import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class Mensualidad {
  ID: number;
  NoConsecutivo?: number; //Mes
  FechaVencimiento?: any;
  FechaPago?: any; //Fecha
  Importe: number; //Importe
  RestaImporte: number;
  CapitalInicial: number; //Capital
  Interes: number; //Interes
  DiasRetraso: number;
  InteresMoratorio: number;
  PagoRequerido: number;
  Seguro: number; //Seguro
  PagoCapital: number; //PagoCapital
  NuevoSaldo: number; //Saldo
  Estatus: string;
  Credito_ID: number;
  GastoAdmin: number;
  DiaMes: number;
  InteresPagado: number;
  MoratorioPagado: number;
  SeguroPagado: number;
  GastoPagado: number;
  DiasTranscurridos?: number;
  PagoMensualidad: number;
  Capital_Aux?: number;
  PagoCapital_Aux?: number;
  NuevoSaldo_Aux?: number;

  constructor() {
    this.ID = 0;
    this.DiasRetraso = 0;
    this.GastoAdmin = 0;
    this.RestaImporte = 0;
    this.InteresMoratorio = 0;
    this.PagoRequerido = 0;
    this.InteresPagado = 0;
    this.MoratorioPagado = 0;
    this.SeguroPagado = 0;
    this.GastoPagado = 0;
    this.Importe = 0;
    this.Estatus = '1';
  }
}
