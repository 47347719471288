import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ControlCreditosService, UsersService } from 'src/app/services/service.index';
import { Router } from '@angular/router';
import { Mensualidad } from '../../models/Mensualidad';

@Component({
  selector: 'app-control-creditos',
  templateUrl: './control-creditos.component.html',
  styleUrls: ['./control-creditos.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class ControlCreditosComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };

  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-md'
  };

  model: any;
  mensualidadDetalles: Mensualidad[] = [];
  pagos: any[] = [];
  descripcion: string = '';
  folio: string = '';
  cliente: string = '';
  Clave: string = '';
  Cliente_ID: any;
  Credito_ID: any;
  Fraccionamieto_ID: any;
  Manzana_ID: any;
  Lote_ID: any;
  estatus = 'V';
  lista: any[] = [];

  visualizar = false;
  manzanas: any[] = [];
  manzanasS: any[] = [];
  fraccionamientosS: any[] = [];
  fraccionamientos: any[] = [];
  lotes: any[] = [];
  lotesS: any[] = [];
  clientes: any[] = [];
  creditos: any[] = [];
  creditosList: any[] = [];
  estatusList = [{ID: 1,  Nombre: 'Todos', Valor: '' }, {ID: 2,  Nombre: 'Vigente', Valor: 'V' }, {ID: 2,  Nombre: 'Pagado', Valor: 'P' }, {ID: 3,  Nombre: 'Cancelado', Valor: 'C' }];

  constructor(private _userService: UsersService, private _creditoService: ControlCreditosService, private modalService: BsModalService, private toastr: ToastrService, public router: Router) { 
    this._userService.loadStorage();
    this._userService.loadFraccionamientoStorage();
  }

  ngOnInit() {
    this.getCombos();
    this.onBuscar();
  }

  onBuscar() {
    this._creditoService.getLista(this.descripcion, this.folio, this.cliente, this.Fraccionamieto_ID, this.Manzana_ID, this.Lote_ID).subscribe(
      (data: any) => {
        this.lista = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  onDelete(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar pago, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: 'Cancelado!',
          text: 'Pago a sido cancelado con exito.',
          type: 'success',
          confirmButtonText: 'Aceptar'
        });
      }
    });
  }

  onSubmit(FormData) {
    if (FormData.valid) {
      this.toastr.success('Pago guardado con exito.', 'Guardado!');
      FormData.resetForm();
      this.modalRef.hide();
    }
  }
  onCerrar() {
    this.router.navigate(['/dashboard']);
  }
  onVisualizar(id) {
    // if ((this.Clave ===  undefined || this.Clave === '') && (this.Credito_ID ===  undefined || this.Credito_ID <= 0)) {
    //   Swal.fire({
    //     title: 'Información!',
    //     text: 'Favor de rellenar el campo clave o seleccionar el crédito',
    //     type: 'info',
    //     confirmButtonText: 'Aceptar'
    //   });
    // } else {
      this._creditoService.getControlCredito(id)
      .subscribe(
        data => {
          this.model = data.model;
          this.mensualidadDetalles = data.mensualidades;
          this.pagos = data.pagos;
          this.router.navigate(['/controlcreditosform'], { state: { model: this.model, mensualidades: this.mensualidadDetalles, pagos: this.pagos} });
        },
        error => this.toastr.error(error.message, 'Error!') );
    // }
    
    // this.router.navigate(['/controlcreditosform']);
  }

  onClienteChanged(selectedValue: any) {
    if (!selectedValue) {
      this.creditosList = [];
      return;
    }
    this.creditosList = [];

    this.creditosList = this.creditos.filter(
      item => item.Cliente_ID === selectedValue.ID
    );
  }

  onCreditoChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.fraccionamientosS = [];

    this.fraccionamientosS = this.fraccionamientos.filter(
      item => item.ID === selectedValue.Fraccionamieto_ID
    );

    this.Fraccionamieto_ID = selectedValue.Fraccionamieto_ID;
    this.manzanasS = this.manzanas.filter(
      item => item.Fraccionamieto_ID === this.Fraccionamieto_ID
    );

    this.Manzana_ID = selectedValue.Manzana_ID;

    this.lotesS = this.lotes.filter(
      item => item.Manzana_ID === this.Manzana_ID
    );

    this.Lote_ID = selectedValue.Lote_ID;

  }

  onFraccionamientoChanged(selectedValue: any) {
    if (!selectedValue) {
      this.manzanasS = [];
      return;
    }
    this.manzanasS = [];

    this.manzanasS = this.manzanas.filter(
      item => item.Fraccionamieto_ID === selectedValue.ID
    );
  }

  onManzanaChanged(selectedValue: any) {
    if (!selectedValue) {
      this.lotesS = [];
      return;
    }
    this.lotesS = [];

    this.lotesS = this.lotes.filter(
      item => item.Manzana_ID === selectedValue.ID
    );
  }

  getCombos() {
    this._creditoService.getCombos()
      .subscribe(
        data => {
          this.manzanas = data.manzanas;
          this.fraccionamientosS = data.fraccionamientos;
          this.lotes = data.lotes;
          this.clientes = data.clientes;
          this.creditos = data.creditos;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

}
