import { Component, OnInit } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { Router } from '@angular/router';
import { ReportesService, UsersService } from 'src/app/services/service.index';

@Component({
  selector: 'app-reporte-pagos',
  templateUrl: './reporte-pagos.component.html',
  styleUrls: ['./reporte-pagos.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class ReportePagosComponent implements OnInit {
  fraccionamientosS: any[] = [];
  clientes: any[] = [];
  estatusList = [{ID: 1,  Nombre: 'Todos', Valor: -1 }, {ID: 2,  Nombre: 'Vigente', Valor: 1 }, {ID: 3,  Nombre: 'Cancelado', Valor: 0 }];
  AgruparList = [{ID: 1,  Nombre: 'Cliente', Valor: 'C' },{ID: 1,  Nombre: 'Fraccionamieto', Valor: 'F' }, {ID: 2,  Nombre: 'Sin Agrupar ', Valor: 'S' }];
  OrdenarList = [{ID: 1,  Nombre: 'Fecha', Valor: 'F' }, {ID: 2,  Nombre: 'Cliente', Valor: 'C' }];
  DetalladoList = [{ID: 1,  Nombre: 'Si', Valor: 'Y' }, {ID: 2,  Nombre: 'No', Valor: 'N' }];

  fraccionamiento: number = -1;
  estatus: number = -1;
  cliente: number = -1;
  Agrupado = 'S';
  Ordenado = 'F';
  Detallado = 'N';
  from: NgbDateStruct;
  to: NgbDateStruct;

  constructor(private _userService: UsersService, private _reporteService: ReportesService, private toastr: ToastrService, public router: Router) {
    this._userService.loadStorage();
    this._userService.loadFraccionamientoStorage();

    // this.fraccionamiento = this._userService.fraccionamiendoID;

    // if (this._userService.fraccionamiendoID === undefined || this._userService.fraccionamiendoID === null ){
    //   this.fraccionamiento = -1;
    // } else {
    //   this.fraccionamiento = this._userService.fraccionamiendoID;
    // }
  }

  ngOnInit() {
    this.getCombos();
  }

  onGenerar(FormData) {
    if (FormData.valid) {
      let from = '';
      let to = '';
      if (this.from !== undefined && this.to !== undefined) {
        from = `${this.from.year}-${this.from.month}-${this.from.day}`;
        to = `${this.to.year}-${this.to.month}-${this.to.day}`;
      }

      const model = {
        from: from,
        to: to,
        fraccionamiento: this.fraccionamiento,
        cliente: this.cliente,
        estatus: this.estatus,
        agrupado: this.Agrupado,
        ordenado: this.Ordenado,
        detallado: this.Detallado
      };
      this._reporteService.imprimirPagos(model).subscribe(
        (data: any ) => {
          const fileblob = new Blob([data], {type: 'application/pdf'});
          if (navigator.appVersion.toString().indexOf('.NET') > 0) {
              window.navigator.msSaveOrOpenBlob(fileblob, 'ReportePagos.pdf');
          } else {
              const fileURL = URL.createObjectURL(fileblob);
              window.open(fileURL);
          }
        },
        (error) => {
          this.toastr.error(error.message, 'Error!');
        }
      );
    }
  }

  getCombos() {
    this._reporteService.getCombos()
      .subscribe(
        data => {
          this.fraccionamientosS = data.fraccionamientosF;
          this.clientes = data.clientes;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  onCerrar() {
    this.router.navigate(['/dashboard']);
  }
}
