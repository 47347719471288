export class ControlCreditoDto {
  Credito_ID: number;
  Fraccionamiento: string;
  Cliente: string;
  FechaCredito: any;
  PrecioLote?: number;
  Enganche?: number;
  TotalCredito?: number;
  DiaPago?: number;
  Plazo?: number;
  CapitalActual?: number;
  InteresAnual?: number;
  PagoMensual?: number;
  PorcSeguro?: number;
  Lote: string;
  Manzana: string;
  LoteMts?: number;
  TipoLote: string;
  Fraccionamieto_ID: number;
  Manzana_ID: number;
  Lote_ID: number;
  Cliente_ID: number;
  PagoRequerido?: number;
  CobrarSeguro?: boolean;
  conAnualidad: boolean;
  noMensAnual?: number;
  anualidad?: number;
  TipoInteres: number;
  TipoInteresDisplay: string;
  
  constructor() {
  }
}
