import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ReportesService, UsersService } from 'src/app/services/service.index';
import { Corrida } from '../../models/Corrida';
import { CorridasService } from '../../services/corridas/corridas.service';
import { CorridaDetalle } from '../../models/CorridaDetalle';

@Component({
  selector: 'app-corridas',
  templateUrl: './corridas.component.html',
  styleUrls: ['./corridas.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class CorridasComponent implements OnInit {

  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  modalNotariaRef: BsModalRef;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };

  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-md'
  };

  configLarge = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'Custom-size-modal'
  };

  from: NgbDateStruct;
  to: NgbDateStruct;
  folio: string = '';
  cliente: string = '';
  estatus: string = '';
  fraccionamiento:any;
  manzana:any;
  lote:any;
  moneda: any;
  lista: any[] = [];

  model: Corrida = new Corrida();
  CorridaDetalle: CorridaDetalle[] = [];
  esEditar = false;
  guardar = true;

  manzanasF: any[] = [];
  manzanas: any[] = [];
  manzanasS: any[] = [];
  fraccionamientosF: any[] = [];
  fraccionamientos: any[] = [];
  lotesF: any[] = [];
  lotes: any[] = [];
  lotesS: any[] = [];
  clientes: any[] = [];
  estatusList = [{ID: 1,  Nombre: 'Todos', Valor: '' }, {ID: 2,  Nombre: 'Vigente', Valor: 'V' }, {ID: 2,  Nombre: 'Pagado', Valor: 'P' }, {ID: 3,  Nombre: 'Cancelado', Valor: 'C' }];
  monedaList = [{ID: 1,  Nombre: 'Todos', Valor: -1 }, {ID: 1,  Nombre: 'MX', Valor: 1 }, {ID: 2,  Nombre: 'DLLS', Valor: 2 }];
  monedaCorridaList = [{ID: 1,  Nombre: 'MX', Valor: 1 }, {ID: 2,  Nombre: 'DLLS', Valor: 2 }];
  tipoIneresList = [{ID: 1,  Tipo: 'Ina360', Valor: 1 }, {ID: 2,  Tipo: 'Ina12', Valor: 2 }];
  mesList = [{ID: 1,  Nombre: 'Enero', Valor: 1 }, 
  {ID: 2,  Nombre: 'Febrero', Valor: 2 }, 
  {ID: 3,  Nombre: 'Marzo', Valor: 3 }, 
  {ID: 4,  Nombre: 'Abril', Valor: 4 },
  {ID: 5,  Nombre: 'Mayo', Valor: 5 },
  {ID: 6,  Nombre: 'Junio', Valor: 6 },
  {ID: 7,  Nombre: 'Julio', Valor: 7 },
  {ID: 8,  Nombre: 'Agosto', Valor: 8 },
  {ID: 9,  Nombre: 'Septiembre', Valor: 9 },
  {ID: 10,  Nombre: 'Octubre', Valor: 10 },
  {ID: 11,  Nombre: 'Noviembre', Valor: 11 },
  {ID: 12,  Nombre: 'Diciembre', Valor: 12 }];

  constructor(private _userService: UsersService, private _corridaService: CorridasService, private _reporteService: ReportesService, private modalService: BsModalService, private toastr: ToastrService) { 
    this._userService.loadStorage();
    this._userService.loadFraccionamientoStorage();

    this.fraccionamiento = this._userService.fraccionamiendoID;
  }

  ngOnInit() {
    this.getCombos();
    this.onBuscar();
  }

  onBuscar() {
    let from = '';
    let to = '';
    if (this.from !== undefined && this.to !== undefined) {
      from = `${this.from.year}-${this.from.month}-${this.from.day}`;
      to = `${this.to.year}-${this.to.month}-${this.to.day}`;
    }

    this._corridaService.getLista(from, to, this.folio, this.cliente, this.estatus, this.fraccionamiento, this.manzana, this.lote, this.moneda).subscribe(
      (data: any) => {
        this.lista = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  getCombos() {
    this._corridaService.getCombos()
      .subscribe(
        data => {
          this.manzanasF = data.manzanasF;
          this.manzanas = data.manzanas;
          this.fraccionamientosF = data.fraccionamientosF;
          this.fraccionamientos = data.fraccionamientos;
          this.lotesF = data.lotesF;
          this.lotes = data.lotes;
          this.clientes = data.clientes;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  onShow(id: number, editar: boolean, template: TemplateRef<any>) {
    this.model = new Corrida();
    this.manzanasS = [];
    this.lotesS = [];
    this.CorridaDetalle = [];
    this.esEditar = editar;
    if (id <= 0) {
      this.model.DiaPago = Number(this.model.Fecha.getDate());
      this.modalRef = this.modalService.show(template, this.configLarge);
    } else {
      this.esEditar = editar;
      this._corridaService.getCorrida(id)
    .subscribe(
      data => {
        this.model = data;
        console.log(this.model);

        this.manzanasS = this.manzanas.filter(
          item => item.Fraccionamieto_ID === this.model.Fraccionamieto_ID
        );
        
        this.lotesS = this.lotes.filter(
          item => item.Manzana_ID === this.model.Manzana_ID
        );

        if (this.model.Fecha) {
          this.model.Fecha = new Date(this.model.Fecha);
          this.model.SelectedDate = this.getDateStructFromDate(this.model.Fecha);
        }
        this.onCalcularCorrida();
        this.modalRef = this.modalService.show(template, this.configLarge);
      },
      error => this.toastr.error(error.message, 'Error!') );
    }
  }

  onSubmit(FormData) {
    if (FormData.valid && this.guardar) {
      this._corridaService.guardar(this.model)
    .subscribe(
      success => {
        this.toastr.success('Registro dado de alta correctamente', 'Guardado!');
        this.onBuscar();
        FormData.resetForm();
        this.modalRef.hide();
      },
      error => {
        this.toastr.error(error.message, 'Error!');
      });
    }
  }

  onDelete(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere cancelar corrida, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Cancelar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        this._corridaService.eliminar(id)
        .subscribe(
          success => {
            this.onBuscar();
            Swal.fire({
              title: 'Cancelado!',
              text: 'Corrida a sido cancelado con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
      }
    });
  }

  onFechaChanged(selectedDate: NgbDateStruct) {
    this.model.Fecha = this.getDateFromDateStruct(selectedDate);
    this.model.DiaPago = Number(this.model.Fecha.getDate());
  }

  getDateFromDateStruct(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  getDateStructFromDate(date: Date): NgbDateStruct {
    const dateStruct: NgbDateStruct = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    };

    return dateStruct;
  }

  onFraccionamientoChanged(selectedValue: any) {
    if (!selectedValue) {
      this.manzanasS = [];
      return;
    }
    this.manzanasS = [];

    this.manzanasS = this.manzanas.filter(
      item => item.Fraccionamieto_ID === selectedValue.ID
    );

    this.model.Interes = selectedValue.InteresAnual;
  }

  onManzanaChanged(selectedValue: any) {
    if (!selectedValue) {
      this.lotesS = [];
      return;
    }
    this.lotesS = [];

    this.lotesS = this.lotes.filter(
      item => item.Manzana_ID === selectedValue.ID
    );
  }
  onLoteChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }

    let fracSelect = this.fraccionamientos.filter(
      item => item.ID === this.model.Fraccionamieto_ID
    )[0];
    this.model.Precio = selectedValue.Precio;
    this.model.Enganche = ((fracSelect.Enganche/100) *  selectedValue.Precio);
    this.model.TotalCredito = this.model.Precio - this.model.Enganche;
  }

  onPlazoChanged() {
    let fracSelect = this.fraccionamientos.filter(
      item => item.ID === this.model.Fraccionamieto_ID
    )[0];

    if (!fracSelect) {
        return;
    }
    this.model.PagoMensual =  ( ((this.model.Interes / 100) / 12 ) * this.model.TotalCredito) / (1 - Math.pow( (1 + (this.model.Interes / 100 ) /  12),  - this.model.Plazo)); 
    let seguro = (this.model.TotalCredito * (fracSelect.PorcSeguro / 100)) / 1000;
    // let mensual = (this.model.PagoMensual + seguro);
    // let ultimoNumero = Number(Math.floor(mensual).toString().slice(-1));
    // let sumaNumero = 0;
    // if (ultimoNumero > 1 && ultimoNumero < 5) {
    //   sumaNumero = 5 - ultimoNumero;
    // } else if (ultimoNumero > 5 && ultimoNumero < 10) {
    //   sumaNumero = 10 - ultimoNumero;
    // }

     this.model.PagoMensual = Number((this.model.PagoMensual + seguro).toFixed(2));
  }

  onCalcularCorrida() {
    this.CorridaDetalle = [];
    this.guardar = true;
    let fechaCorrida = new Date(this.model.Fecha.getFullYear(), this.model.Fecha.getMonth() , this.model.Fecha.getDate());
    let fechaCorridaPrimera = new Date(this.model.Fecha.getFullYear(), this.model.Fecha.getMonth() , this.model.Fecha.getDate());
    let fracSelect = this.fraccionamientos.filter(
      item => item.ID === this.model.Fraccionamieto_ID
    )[0];

    let incrementoMes = 1;
    let ban = true;
    let index = 0;
    for (index; ban; index++) {
      let PagoMensual = 0;

      //fechaCorrida = new Date(fechaCorrida.setMonth(fechaCorrida.getMonth() + index));
      // let month = Number(fechaCorrida.getMonth());
      // fechaCorrida.setMonth(month + incrementoMes);
      fechaCorrida = this.addMonths(fechaCorrida, incrementoMes);
      let noMes = Number((fechaCorrida.getMonth() + 1));
      let detalle = new CorridaDetalle();

      // Numero de mes
      detalle.ID = index + 1;

      // Dia Pago
      if (Number((fechaCorrida.getMonth() + 1))  === 2) {
        if (Number(this.model.DiaPago) === 31 || Number(this.model.DiaPago) === 30 || Number(this.model.DiaPago) === 29) {
          if (this.onAnoEsBiciesto(fechaCorrida.getFullYear())) {
            detalle.Mes = 29;
          } else {
            let dia = this.onDiasEnMes(fechaCorrida.getMonth() + 1, fechaCorrida.getFullYear());
            detalle.Mes = dia;
          }
        } else {
          detalle.Mes = this.model.DiaPago;
        }
      } else {
        if (Number(this.model.DiaPago) === 31) {
          let dia31 = this.onDiasEnMes(fechaCorrida.getMonth() + 1, fechaCorrida.getFullYear());
          detalle.Mes = dia31;
        } else {
          detalle.Mes = this.model.DiaPago;
        }
      }

      let fechaCadena = fechaCorrida;
      //fechaCadena.setDate(detalle.Mes);
      detalle.Fecha = this.onFormatDateToString(fechaCadena, detalle.Mes);

      // Importe
      // if (index + 1 === Number(this.model.Plazo)) {
      //   PagoMensual = this.CorridaDetalle[index - 1 ].Saldo;
      //   detalle.Importe = PagoMensual;
      // } else {
      //   detalle.Importe = this.model.PagoMensual;
      // }

      // ORIGINAL
      // detalle.Importe = this.model.PagoMensual;
      // if (index !== 0) {
      //    if (this.CorridaDetalle[index - 1 ].Saldo <= this.model.PagoMensual) {
      //       PagoMensual = this.CorridaDetalle[index - 1].Saldo;
      //       detalle.Importe = PagoMensual;
      //       ban = false;
      //    }
      // }

      // NUEVO TOMADO DE CREDITO
      detalle.Importe = 0;
      if (noMes === Number(this.model.noMensAnual)) {
        detalle.Importe = this.model.anualidad;
        detalle.RestaImporte = this.model.anualidad;
        detalle.PagoMensualidad = this.model.anualidad;
      } else {
        detalle.Importe = this.model.PagoMensual;
        detalle.RestaImporte = this.model.PagoMensual;
        detalle.PagoMensualidad = this.model.PagoMensual;
      }

      if (index !== 0) {
        if (this.model.conAnualidad) {
          if (this.CorridaDetalle[index - 1 ].Saldo <= detalle.Importe) {
            PagoMensual = this.CorridaDetalle[index - 1].Saldo;
            detalle.Importe = 0;
            detalle.Importe = PagoMensual;
            detalle.RestaImporte = PagoMensual;
            console.log(detalle.RestaImporte);
            ban = false;
          }
        } else {
          if (this.CorridaDetalle[index - 1 ].Saldo <= this.model.PagoMensual) {
            PagoMensual = this.CorridaDetalle[index - 1].Saldo;
            detalle.Importe = 0;
            detalle.Importe = PagoMensual;
            detalle.RestaImporte = PagoMensual;
  
            ban = false;
          }
        }
           
      }

      // Capital
      if (index === 0) {
        detalle.Capital = this.model.TotalCredito;
      } else {
        detalle.Capital = this.CorridaDetalle[index - 1 ].Saldo;
      }

      // Interes
      let diasTranscurridos = 0;
      if (this.model.TipoInteres == 1) {
        if (index === 0) {
          let dateParts = detalle.Fecha.split('/');
          // month is 0-based, that's why we need dataParts[1] - 1
          let dateObject = new Date(+dateParts[2], Number(dateParts[1]) - 1, +dateParts[0]);
  
          let difference_In_Time = dateObject.getTime() - fechaCorridaPrimera.getTime();
          let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
  
           diasTranscurridos = difference_In_Days;
           detalle.Interes = ((detalle.Capital) * (this.model.Interes/100) / 360) * diasTranscurridos;
        } else {
          let dateParts1 = detalle.Fecha.split('/');
          let dateObject1 = new Date(+dateParts1[2], Number(dateParts1[1]) - 1, +dateParts1[0]);
  
          let dateParts2 = this.CorridaDetalle[index - 1 ].Fecha.split('/');
          let dateObject2 = new Date(+dateParts2[2], Number(dateParts2[1]) - 1, +dateParts2[0]);
  
          let difference_In_Time = dateObject1.getTime() - dateObject2.getTime();
          let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
  
          diasTranscurridos = difference_In_Days;
          detalle.Interes = ((this.CorridaDetalle[index - 1 ].Saldo) * (this.model.Interes/100) / 360) * diasTranscurridos;
        }
      } else {
        if (index === 0) {
          detalle.Interes = ((detalle.Capital) * (this.model.Interes/100) / 12);
        } else {
          detalle.Interes = ((this.CorridaDetalle[index - 1 ].Saldo) * (this.model.Interes/100) / 12);
        }
      }
      
      // if (index >= 1) {
      //   detalle.Interes = ((this.CorridaDetalle[index - 1 ].Saldo) * (this.model.Interes/100) / 360) * (this.CorridaDetalle[index - 1 ].Mes); //detalle.Mes;
      // } else {
      //   detalle.Interes = ((detalle.Capital) * (this.model.Interes/100) / 360) * detalle.Mes ;
      // }

      // Seguro
      // if (index >= 1) {
      //   detalle.Seguro = (this.CorridaDetalle[index - 1 ].Saldo * (fracSelect.PorcSeguro / 100)) / 1000;
      // } else {
      //   detalle.Seguro = 0;
      // }
      // NUEVO
      if (index >= 1) {
        detalle.Seguro = (this.CorridaDetalle[index - 1 ].Saldo * (fracSelect.PorcSeguro / 100)) / 1000;
      } else {
        if(this.model.CobrarSeguro) {
          detalle.Seguro = (detalle.Capital * (fracSelect.PorcSeguro / 100)) / 1000;
        } else {
          detalle.Seguro = 0;
        }
      }
      
      // if (!ban) {
      //   detalle.Importe = detalle.Importe + detalle.Interes + detalle.Seguro;
      // }

      if (!ban) {
        //detalle.Importe = 0; // detalle.Importe + detalle.Interes + detalle.Seguro;
        detalle.Importe = detalle.Importe + detalle.Interes + detalle.Seguro;
        detalle.RestaImporte = detalle.RestaImporte + detalle.Interes + detalle.Seguro;
        
        detalle.PagoMensualidad = detalle.RestaImporte;
      }

      // Capital
      detalle.PagoCapital = detalle.Importe - detalle.Interes - detalle.Seguro;

      // Saldo
      detalle.Saldo = detalle.Capital - detalle.PagoCapital;

      this.CorridaDetalle.push(detalle);
    }

    if (!this.model.conAnualidad) {
      if (index !== Number(this.model.Plazo)) {
        this.guardar = false;
        Swal.fire({
          title: 'Error Corrida!',
          text: 'Pago mensual NO corresponde al importe al plazo estimado, por favor, cambie el pago mensual y vuelva a recalcular. Numero de pazo generado: '+ index,
          type: 'error',
          confirmButtonText: 'Aceptar'
        });
       } else {
        this.guardar = true;
       }
    } else {
      this.guardar = true;
      let plazoAnualidad = index + 1;
      Swal.fire({
        title: 'Corrida con anualidad genero '+ plazoAnualidad +' mensualidades de plazo?',
        text: 'Esta seguro que quiere guardar los plazos.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Guardar!',
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false
      }).then((result) => {
        if (result.value) {
          this.model.Plazo = plazoAnualidad
        }
      });
    }
    
  //   if (index !== Number(this.model.Plazo)) {
  //     this.guardar = false;
  //     Swal.fire({
  //       title: 'Error Corrida!',
  //       text: 'Pago mensual NO corresponde al importe al plazo estimado, por favor, cambie el pago mensual y vuelva a recalcular. Numero de pazo generado: '+ index,
  //       type: 'error',
  //       confirmButtonText: 'Aceptar'
  //     });
  //  }
  }

  onAnoEsBiciesto(year: number) {
    return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
  }
  onDiasEnMes(month: number, year: number) {
    return new Date(year, month, 0).getDate();
  }

  onFormatDateToString(date, day) {
    // 01, 02, 03, ... 29, 30, 31
    var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
    // 01, 02, 03, ... 10, 11, 12
    var MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
    // 1970, 1971, ... 2015, 2016, ...
    var yyyy = date.getFullYear();

    var ddf = (day < 10 ? '0' : '') + day;

    // create the format you want
    return (ddf + '/' + MM + '/' + yyyy);
 }

 // tslint:disable-next-line: ban-types
 addMonths(date: Date, months: Number) {
  let d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
  }

  onImprimir(id: number) {
    this.model = new Corrida();
    this.manzanasS = [];
    this.lotesS = [];
    this.CorridaDetalle = [];

    this._corridaService.getCorrida(id)
    .subscribe(
      data => {
        this.model = data;
        console.log(this.model);

        this.manzanasS = this.manzanas.filter(
          item => item.Fraccionamieto_ID === this.model.Fraccionamieto_ID
        );
        
        this.lotesS = this.lotes.filter(
          item => item.Manzana_ID === this.model.Manzana_ID
        );

        if (this.model.Fecha) {
          this.model.Fecha = new Date(this.model.Fecha);
          this.model.SelectedDate = this.getDateStructFromDate(this.model.Fecha);
        }
        this.onCalcularCorrida();
        const model = {
          corrida: this.model,
          detallecorrida: this.CorridaDetalle
        };
        this._reporteService.imprimirCorrida(model).subscribe(
          (data: any ) => {
            const fileblob = new Blob([data], {type: 'application/pdf'});
            if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                window.navigator.msSaveOrOpenBlob(fileblob, 'ReportePagos.pdf');
            } else {
                const fileURL = URL.createObjectURL(fileblob);
                window.open(fileURL);
            }
          },
          (error) => {
            this.toastr.error(error.message, 'Error!');
          }
        );
      },
      error => this.toastr.error(error.message, 'Error!') );
  }

  onAnualidadChanged(){
    if (!this.model.conAnualidad) {
      this.model.noMensAnual = null;
      this.model.anualidad = null;
    }
  }
}
