import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UsersService, CreditosService, ReportesService } from 'src/app/services/service.index';
import { Credito } from 'src/app/models/Credito';
import { Mensualidad } from '../../models/Mensualidad';

@Component({
  selector: 'app-creditos',
  templateUrl: './creditos.component.html',
  styleUrls: ['./creditos.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class CreditosComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  modalNotariaRef: BsModalRef;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };

  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-md'
  };

  configLarge = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'Custom-size-modal'
  };

  from: NgbDateStruct;
  to: NgbDateStruct;
  folio: string = '';
  cliente: string = '';
  estatus: string = 'V';
  fraccionamiento:any;
  manzana:any;
  lote:any;
  moneda: any;
  lista: any[] = [];

  model: Credito = new Credito();
  mensualidadDetalles: Mensualidad[] = [];
  tmpMensualidadDetalles: Mensualidad[] = [];
  esEditar = false;
  guardar = true;

  manzanasF: any[] = [];
  manzanas: any[] = [];
  manzanasS: any[] = [];
  fraccionamientosF: any[] = [];
  fraccionamientos: any[] = [];
  lotesF: any[] = [];
  lotes: any[] = [];
  lotesS: any[] = [];
  clientes: any[] = [];
  notarias: any[] = [];
  estatusList = [{ID: 1,  Nombre: 'Todos', Valor: '' }, {ID: 2,  Nombre: 'Vigente', Valor: 'V' }, {ID: 2,  Nombre: 'Pagado', Valor: 'P' }, {ID: 3,  Nombre: 'Cancelado', Valor: 'C' }];
  monedaList = [{ID: 1,  Nombre: 'Todos', Valor: -1 }, {ID: 1,  Nombre: 'MX', Valor: 1 }, {ID: 2,  Nombre: 'USD', Valor: 2 }];
  monedaCreditoList = [{ID: 1,  Nombre: 'MX', Valor: 1 }, {ID: 2,  Nombre: 'USD', Valor: 2 }];
  estatusNotariaList = [{ID: 1,  Nombre: 'No Aplica', Valor: 0 }, {ID: 2,  Nombre: 'En Proceso', Valor: 1 }, {ID: 3,  Nombre: 'Liberado', Valor: 1 }];
  mesList = [{ID: 1,  Nombre: 'Enero', Valor: 1 }, 
             {ID: 2,  Nombre: 'Febrero', Valor: 2 }, 
             {ID: 3,  Nombre: 'Marzo', Valor: 3 }, 
             {ID: 4,  Nombre: 'Abril', Valor: 4 },
             {ID: 5,  Nombre: 'Mayo', Valor: 5 },
             {ID: 6,  Nombre: 'Junio', Valor: 6 },
             {ID: 7,  Nombre: 'Julio', Valor: 7 },
             {ID: 8,  Nombre: 'Agosto', Valor: 8 },
             {ID: 9,  Nombre: 'Septiembre', Valor: 9 },
             {ID: 10,  Nombre: 'Octubre', Valor: 10 },
             {ID: 11,  Nombre: 'Noviembre', Valor: 11 },
             {ID: 12,  Nombre: 'Diciembre', Valor: 12 }];
  tipoIneresList = [{ID: 1,  Tipo: 'Ina360', Valor: 1 }, {ID: 2,  Tipo: 'Ina12', Valor: 2 }];

  constructor(private _userService: UsersService, private _creditoService: CreditosService, private _reporteService: ReportesService, private modalService: BsModalService, private toastr: ToastrService) { 
    this._userService.loadStorage();
    this._userService.loadFraccionamientoStorage();

    this.fraccionamiento = this._userService.fraccionamiendoID;
  }

  ngOnInit() {
    this.getCombos();
    this.onBuscar();
  }

  onBuscar() {
    let from = '';
    let to = '';
    if (this.from !== undefined && this.to !== undefined) {
      from = `${this.from.year}-${this.from.month}-${this.from.day}`;
      to = `${this.to.year}-${this.to.month}-${this.to.day}`;
    }

    this._creditoService.getLista(from, to, this.folio, this.cliente, this.estatus, this.fraccionamiento, this.manzana, this.lote, this.moneda).subscribe(
      (data: any) => {
        this.lista = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  getCombos() {
    this._creditoService.getCombos()
      .subscribe(
        data => {
          this.manzanasF = data.manzanasF;
          this.manzanas = data.manzanas;
          this.fraccionamientosF = data.fraccionamientosF;
          this.fraccionamientos = data.fraccionamientos;
          this.lotesF = data.lotesF;
          this.lotes = data.lotes;
          this.clientes = data.clientes;
          this.notarias = data.notarias;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  onShow(id: number, editar: boolean, template: TemplateRef<any>) {
    this.model = new Credito();
    this.manzanasS = [];
    this.lotesS = [];
    this.mensualidadDetalles = [];
    this.esEditar = editar;
    if (id <= 0) {
      this.model.DiaPago = Number(this.model.FechaVenta.getDate());
      this.modalRef = this.modalService.show(template, this.configLarge);
    } else {
      this.esEditar = editar;
      this._creditoService.getCorrida(id)
    .subscribe(
      data => {
        this.model = data.model;
        this.mensualidadDetalles = data.mensualidades;

        this.manzanasS = this.manzanas.filter(
          item => item.Fraccionamieto_ID === this.model.Fraccionamieto_ID
        );

        this.lotesS = this.lotes.filter(
          item => item.Manzana_ID === this.model.Manzana_ID
        );

        if (this.model.FechaVenta) {
          this.model.FechaVenta = new Date(this.model.FechaVenta);
          this.model.SelectedDate = this.getDateStructFromDate(this.model.FechaVenta);
        }
        this.modalRef = this.modalService.show(template, this.configLarge);
      },
      error => this.toastr.error(error.message, 'Error!') );
    }
  }

  onSubmit(FormData) {
    if (FormData.valid && this.guardar) {

      this.model.CapitalActual = this.model.TotalCredito;
      
      if (this.mensualidadDetalles.length === 0) {
        this.toastr.warning('Favor de calcular corrida', 'Precaución!');
        return;
      }

      const model = {
        model: this.model,
        mensualidades: this.mensualidadDetalles
      };

      this._creditoService.guardar(model)
    .subscribe(
      success => {
        this.toastr.success('Crédito guardado con exito.', 'Guardado!');
        this.onBuscar();
        FormData.resetForm();
        this.modalRef.hide();
      },
      error => {
        this.toastr.error(error.message, 'Error!');
      });
    }
  }

  async onCancelar(id: number) {
    const { value: formValues } = await Swal.fire({
      title: 'Cancelar',
      html:
        'Observación: <br> <input id="swal-input1" class="swal2-input">' ,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      preConfirm: () => {
        return [
          (<any>document.getElementById('swal-input1')).value
        ];
      }
    });

    if (formValues) {
      let obs = formValues[0];

      const modelAut = {
        id: id,
        motivo: obs,
      };

      this._creditoService.cancelar(modelAut)
      .subscribe(
        success => {
          Swal.fire({
            title: 'Cancelado!',
            text: 'Credito cancelado con exito.',
            type: 'success',
            confirmButtonText: 'Aceptar'
          });
          this.onBuscar();
        },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
    }
  }

  onFechaChanged(selectedDate: NgbDateStruct) {
    this.model.FechaVenta = this.getDateFromDateStruct(selectedDate);
    this.model.DiaPago = Number(this.model.FechaVenta.getDate());
  }

  onFechaContratoChanged(selectedDate: NgbDateStruct) {
    this.model.FechaContrato = this.getDateFromDateStruct(selectedDate);
  }

  getDateFromDateStruct(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  getDateStructFromDate(date: Date): NgbDateStruct {
    const dateStruct: NgbDateStruct = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    };

    return dateStruct;
  }

  onFraccionamientoChanged(selectedValue: any) {
    if (!selectedValue) {
      this.manzanasS = [];
      return;
    }
    this.manzanasS = [];

    this.manzanasS = this.manzanas.filter(
      item => item.Fraccionamieto_ID === selectedValue.ID
    );

    this.model.Interes = selectedValue.InteresAnual;
    this.model.Descripcion = selectedValue.Nombre;
  }

  onManzanaChanged(selectedValue: any) {
    if (!selectedValue) {
      this.lotesS = [];
      return;
    }
    this.lotesS = [];

    this.lotesS = this.lotes.filter(
      item => item.Manzana_ID === selectedValue.ID
    );

    this.model.Descripcion = this.model.Descripcion + ' -  Manzana: ' + selectedValue.Clave;
  }

  onLoteChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }

    let fracSelect = this.fraccionamientos.filter(
      item => item.ID === this.model.Fraccionamieto_ID
    )[0];

    this.model.Precio = selectedValue.Precio;
    this.model.Enganche = ((fracSelect.Enganche/100) *  selectedValue.Precio);
    this.model.TotalCredito = this.model.Precio - this.model.Enganche;
    this.model.Descripcion = this.model.Descripcion + ' -  Lote: ' + selectedValue.Clave;
    this.model.CapitalActual = this.model.TotalCredito;
  }

  onPlazoChanged() {
    let fracSelect = this.fraccionamientos.filter(
      item => item.ID === this.model.Fraccionamieto_ID
    )[0];

    this.model.PagoMensual = ( ((this.model.Interes / 100) / 12 ) * this.model.TotalCredito) / (1 - Math.pow( (1 + (this.model.Interes / 100 ) /  12),  - this.model.Plazo)); 
    let seguro = (this.model.TotalCredito * (fracSelect.PorcSeguro / 100)) / 1000;

    this.model.PagoMensual = Number((this.model.PagoMensual + seguro).toFixed(2));
  }

  onShowNotaria(template: TemplateRef<any>) {
    this.modalNotariaRef = this.modalService.show(template, this.configMd);
  }

  onSubmitNotaria(FormData: any) {
    this.toastr.success('Notaria guardada con exito.', 'Agregado!');
    this.modalNotariaRef.hide();
  }

  onAnoEsBiciesto(year: number) {
    return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
  }
  onDiasEnMes(month: number, year: number) {
    return new Date(year, month, 0).getDate();
  }

  onFormatDateToString(date, day) {
    // 01, 02, 03, ... 29, 30, 31
    var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
    // 01, 02, 03, ... 10, 11, 12
    var MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
    // 1970, 1971, ... 2015, 2016, ...
    var yyyy = date.getFullYear();

    var ddf = (day < 10 ? '0' : '') + day;

    // create the format you want
    return (ddf + '/' + MM + '/' + yyyy);
 }

 addMonths(date: Date, months: Number) {
  let d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
}

 onCalcularCorrida() {
  this.tmpMensualidadDetalles = [];
  let fechaCorrida = new Date(this.model.FechaVenta.getFullYear(), this.model.FechaVenta.getMonth() , this.model.FechaVenta.getDate());
  let fechaCorridaPrimera = new Date(this.model.FechaVenta.getFullYear(), this.model.FechaVenta.getMonth() , this.model.FechaVenta.getDate());
  let fracSelect = this.fraccionamientos.filter(
    item => item.ID === this.model.Fraccionamieto_ID
  )[0];

  let incrementoMes = 1;
  let ban = true;
  let index = 0;
  for (index; ban; index++) {
    let PagoMensual = 0;

    fechaCorrida = this.addMonths(fechaCorrida, incrementoMes);
    let noMes = Number((fechaCorrida.getMonth() + 1));
    let detalle = new Mensualidad();

    // Numero de mes
    if (this.model.ID > 0) {

      let currentMensualidad = this.mensualidadDetalles.filter(
        item => item.ID === (index + 1)
      );
      if (currentMensualidad.length > 0) {
        detalle.ID = currentMensualidad[0].ID;
        detalle.NoConsecutivo = currentMensualidad[0].NoConsecutivo;
        detalle.Estatus = currentMensualidad[0].Estatus;
      } else {
        detalle.ID = 0;
        detalle.NoConsecutivo = index + 1;
      }
    } else {
      detalle.NoConsecutivo = index + 1;
    }

    // Dia Pago
    if (Number((fechaCorrida.getMonth() + 1))  === 2) {
      if (Number(this.model.DiaPago) === 31 || Number(this.model.DiaPago) === 30 || Number(this.model.DiaPago) === 29) {
        if (this.onAnoEsBiciesto(fechaCorrida.getFullYear())) {
          detalle.DiaMes = 29;
        } else {
          let dia = this.onDiasEnMes(fechaCorrida.getMonth() + 1, fechaCorrida.getFullYear());
          detalle.DiaMes = dia;
        }
      } else {
        detalle.DiaMes = this.model.DiaPago;
      }
    } else {
      if (Number(this.model.DiaPago) === 31) {
        let dia31 = this.onDiasEnMes(fechaCorrida.getMonth() + 1, fechaCorrida.getFullYear());
        detalle.DiaMes = dia31;
      } else {
        detalle.DiaMes = this.model.DiaPago;
      }
    }

    let fechaCadena = fechaCorrida;

    detalle.FechaVencimiento = new Date(fechaCadena.getFullYear(), fechaCadena.getMonth() , detalle.DiaMes);

    // Importe
    // if (Number((index + 1)) === Number(this.model.Plazo)) {
    //   PagoMensual = this.tmpMensualidadDetalles[index - 1 ].NuevoSaldo;
    //   detalle.Importe = 0; // PagoMensual;
    //   detalle.PagoRequerido = PagoMensual;
    //   detalle.RestaImporte = PagoMensual;
    // } else {
    //   detalle.Importe = 0; // this.model.PagoMensual;
    //   detalle.PagoRequerido = this.model.PagoMensual;
    //   detalle.RestaImporte = this.model.PagoMensual;
    // }
    detalle.Importe = 0;
    if (noMes === Number(this.model.noMensAnual)) {
      detalle.PagoRequerido = this.model.anualidad;
      detalle.RestaImporte = this.model.anualidad;
      detalle.PagoMensualidad = this.model.anualidad;
    } else {
      detalle.PagoRequerido = this.model.PagoMensual;
      detalle.RestaImporte = this.model.PagoMensual;
      detalle.PagoMensualidad = this.model.PagoMensual;
    }

    if (index !== 0) {
      if (this.model.conAnualidad) {
        if (this.tmpMensualidadDetalles[index - 1 ].NuevoSaldo <= detalle.PagoRequerido) {
          PagoMensual = this.tmpMensualidadDetalles[index - 1].NuevoSaldo;
          detalle.Importe = 0;
          detalle.PagoRequerido = PagoMensual;
          detalle.RestaImporte = PagoMensual;
          console.log(detalle.RestaImporte);
          ban = false;
        }
      } else {
        if (this.tmpMensualidadDetalles[index - 1 ].NuevoSaldo <= this.model.PagoMensual) {
          PagoMensual = this.tmpMensualidadDetalles[index - 1].NuevoSaldo;
          detalle.Importe = 0;
          detalle.PagoRequerido = PagoMensual;
          detalle.RestaImporte = PagoMensual;

          ban = false;
        }
      }
         
    }

    // Capital
    if (index === 0) {
      detalle.CapitalInicial = this.model.TotalCredito;
    } else {
      detalle.CapitalInicial = this.tmpMensualidadDetalles[index - 1 ].NuevoSaldo;
    }

    // Interes
    // if (index >= 1) {
    //   detalle.Interes = ((this.tmpMensualidadDetalles[index - 1 ].NuevoSaldo) * (this.model.Interes/100) / 360) * (this.tmpMensualidadDetalles[index - 1 ].DiaMes); //detalle.DiaMes;
    // } else {
    //   detalle.Interes = ((detalle.CapitalInicial) * (this.model.Interes/100) / 360) * detalle.DiaMes ;
    // }
    let diasTranscurridos = 0;
    if (this.model.TipoInteres == 1) {
      if (index === 0) {
        let difference_In_Time = detalle.FechaVencimiento.getTime() - fechaCorridaPrimera.getTime();
        let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);

        diasTranscurridos = difference_In_Days;
        detalle.DiasTranscurridos = diasTranscurridos;
        detalle.Interes = ((detalle.CapitalInicial) * (this.model.Interes/100) / 360) * diasTranscurridos;
      } else {
        let difference_In_Time = detalle.FechaVencimiento.getTime() - this.tmpMensualidadDetalles[index - 1 ].FechaVencimiento.getTime();
        let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);

        diasTranscurridos = difference_In_Days;
        detalle.DiasTranscurridos = diasTranscurridos;
        detalle.Interes = ((this.tmpMensualidadDetalles[index - 1 ].NuevoSaldo) * (this.model.Interes/100) / 360) * diasTranscurridos;
      }
    } else {
      if (index === 0) {
        detalle.Interes = ((detalle.CapitalInicial) * (this.model.Interes/100) / 12);
      } else {
        detalle.Interes = ((this.tmpMensualidadDetalles[index - 1 ].NuevoSaldo) * (this.model.Interes/100) / 12);
      }
    }
    


    // Seguro
    if (index >= 1) {
      detalle.Seguro = (this.tmpMensualidadDetalles[index - 1 ].NuevoSaldo * (fracSelect.PorcSeguro / 100)) / 1000;
    } else {
      if(this.model.CobrarSeguro) {
        detalle.Seguro = (detalle.CapitalInicial * (fracSelect.PorcSeguro / 100)) / 1000;
      } else {
        detalle.Seguro = 0;
      }
    }

    if (!ban) {
      detalle.Importe = 0; // detalle.Importe + detalle.Interes + detalle.Seguro;
      detalle.PagoRequerido = detalle.PagoRequerido + detalle.Interes + detalle.Seguro;
      detalle.RestaImporte = detalle.RestaImporte + detalle.Interes + detalle.Seguro;
      
      detalle.PagoMensualidad = detalle.RestaImporte;
    }

    // Capital
    detalle.PagoCapital = detalle.PagoRequerido - detalle.Interes - detalle.Seguro; // detalle.Importe - detalle.Interes - detalle.Seguro;

    // Saldo
    detalle.NuevoSaldo = detalle.CapitalInicial - detalle.PagoCapital;

    this.tmpMensualidadDetalles.push(detalle);

  }

  this.mensualidadDetalles = this.tmpMensualidadDetalles;

  if (!this.model.conAnualidad) {
    if (index !== Number(this.model.Plazo)) {
      this.guardar = false;
      Swal.fire({
        title: 'Error Corrida!',
        text: 'Pago mensual NO corresponde al importe al plazo estimado, por favor, cambie el pago mensual y vuelva a recalcular. Numero de pazo generado: '+ index,
        type: 'error',
        confirmButtonText: 'Aceptar'
      });
     } else {
      this.guardar = true;
     }
  } else {
    this.guardar = true;
    let plazoAnualidad = index + 1;
    Swal.fire({
      title: 'Corrida con anualidad genero '+ plazoAnualidad +' mensualidades de plazo?',
      text: 'Esta seguro que quiere guardar los plazos.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Guardar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        this.model.Plazo = plazoAnualidad
      }
    });
  }
 }

 onImprimir(id: number) {
  const model = {
    id: id
  };
  this._reporteService.imprimirCredito(model).subscribe(
    (data: any ) => {
      const fileblob = new Blob([data], {type: 'application/pdf'});
      if (navigator.appVersion.toString().indexOf('.NET') > 0) {
          window.navigator.msSaveOrOpenBlob(fileblob, 'ReportePagos.pdf');
      } else {
          const fileURL = URL.createObjectURL(fileblob);
          window.open(fileURL);
      }
    },
    (error) => {
      this.toastr.error(error.message, 'Error!');
    }
  );
  }

  onAnualidadChanged(){
    if (!this.model.conAnualidad) {
      this.model.noMensAnual = null;
      this.model.anualidad = null;
    }
  }
}
