export class Manzana {
  ID: number;
  Clave: string;
  NoLotes?: number;
  NoLotesVendido?: number;
  Estatus?: boolean;
  Fraccionamieto_ID: number;

  constructor() {
    this.ID = 0;
    this.Estatus = true;
    this.NoLotes = 0;
    this.NoLotesVendido = 0;
  }
}
