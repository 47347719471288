import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { AppSettings } from '../../models/app-settings';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class CreditosService {

  private _url = `${AppSettings.API_ENDPOINT}/Creditos`;
  private _getLista = `${this._url}/Lista`;
  private _getGetCredito = `${this._url}/GetCredito`;
  private _guardar = `${this._url}/Guardar`;
  private _cancelar = `${this._url}/Cancelar`;
  private _getCombos = `${this._url}/Combos`;

  constructor(public _http: HttpClient, private _userService: UsersService) {
    this._userService.loadStorage();
  }

  getLista(from: String, to: String, folio: String, cliente: String, estatus: String, fraccionamiento: number, manzana: number, lote: number, moneda: number): Observable<any[]> {
    const params = new HttpParams()
      .set('from', from.toString())
      .set('to', to.toString())
      .set('folio', folio.toString())
      .set('cliente', cliente.toString())
      .set('estatus', estatus.toString())
      .set('fraccionamiento', String(fraccionamiento))
      .set('manzana', String(manzana))
      .set('lote', String(lote))
      .set('moneda', String(moneda));

    return this._http.get<any[]>(this._getLista, { params: params, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getCorrida(id: number): Observable<any>  {
    return this._http.get<any>(`${this._getGetCredito}/${id}`, { headers: this._userService.header})
      .pipe(
        tap(data => data),
        catchError(this.handleError)
      );
  }

  guardar(cliente: any): Observable<any> {
    return this._http.post<any>(`${this._guardar}`, cliente, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  cancelar(model: any) {
    return this._http.post<any>(`${this._cancelar}`, model, { headers: this._userService.header}).pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }
  
  getCombos(): Observable<any> {
    return this._http.get<any>(this._getCombos, { params: null, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  // Handdle Error methor for observale
  private handleError(err: HttpErrorResponse) {
    return throwError(err.error);
  }
}
