import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ReportesService, UsersService } from 'src/app/services/service.index';
import { Pago } from '../../models/Pago';
import { PagosService } from '../../services/pagos/pagos.service';

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class PagosComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };

  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-md'
  };

  from: NgbDateStruct;
  to: NgbDateStruct;
  folio: string = '';
  cliente: string = '';
  descripcion: string = '';
  estatus: any;
  fraccionamiento:any;
  manzana:any;
  lote:any;
  moneda: any;
  bancario: any;
  formapago: any;
  lista: any[] = [];

  model: Pago = new Pago();
  pagoEsperado:any;
  pagoTotalRequerido:any;
  excedePago = false;
  esEditar = false;

  manzanasF: any[] = [];
  fraccionamientosF: any[] = [];
  lotesF: any[] = [];
  clientes: any[] = [];
  formapagos: any[] = [];
  formapagosF: any[] = [];
  cuentas: any[] = [];
  cuentasF: any[] = [];
  creditos: any[] = [];
  creditosList: any[] = [];

  estatusList = [{ID: 1,  Nombre: 'Todos', Valor: -1 }, {ID: 2,  Nombre: 'Vigente', Valor: 1 }, {ID: 3,  Nombre: 'Cancelado', Valor: 0 }];
  monedaList = [{ID: 1,  Nombre: 'Todos', Valor: -1 }, {ID: 1,  Nombre: 'MX', Valor: 1 }, {ID: 2,  Nombre: 'USD', Valor: 2 }];
  monedaPagosList = [{ID: 1,  Nombre: 'MX', Valor: 1 }, {ID: 2,  Nombre: 'USD', Valor: 2 }];
  formas = [{ID: 1,  Nombre: 'Abono a Capital ', Valor: true }, {ID: 2,  Nombre: 'Pagos Anticipados', Valor: false }];

  constructor(private _userService: UsersService, private _pagoService: PagosService, private _reporteService: ReportesService, private modalService: BsModalService, private toastr: ToastrService) {
    this._userService.loadStorage();
    this._userService.loadFraccionamientoStorage();

    this.fraccionamiento = this._userService.fraccionamiendoID;
  }

  ngOnInit() {
    this.getCombos();
    this.onBuscar();
  }

  onBuscar() {
    let from = '';
    let to = '';
    if (this.from !== undefined && this.to !== undefined) {
      from = `${this.from.year}-${this.from.month}-${this.from.day}`;
      to = `${this.to.year}-${this.to.month}-${this.to.day}`;
    }

    this._pagoService.getLista(from, to, this.folio, this.cliente, this.descripcion, this.estatus, this.fraccionamiento, this.manzana, this.lote, this.moneda, this.bancario, this.formapago).subscribe(
      (data: any) => {
        this.lista = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  getCombos() {
    this._pagoService.getCombos()
      .subscribe(
        data => {
          this.manzanasF = data.manzanasF;
          this.fraccionamientosF = data.fraccionamientosF;
          this.lotesF = data.lotesF;
          this.clientes = data.clientes;
          this.formapagos = data.formapagos;
          this.formapagosF = data.formapagosF;
          this.cuentas = data.cuentas;
          this.cuentasF = data.cuentasF;
          this.creditos = data.creditos;
        },
        error => this.toastr.error(error.message, 'Error!', {disableTimeOut: true, closeButton: true}) );
  }

  onShow(id: number, editar: boolean, template: TemplateRef<any>) {
    this.model = new Pago();
    this.creditosList = [];
    this.pagoEsperado = 0;
    this.pagoTotalRequerido = 0;
    this.esEditar = editar;
    this.excedePago = false;
    if (id <= 0) {
      this.modalRef = this.modalService.show(template, this.config);
    } else {
      this.esEditar = editar;
      this._pagoService.getPago(id)
    .subscribe(
      data => {
        this.model = data.model;

        this.creditosList = this.creditos.filter(
          item => item.Cliente_ID === this.model.Cliente_ID
        );

        this.pagoEsperado = this.creditosList.filter(
          item => item.ID === this.model.Credito_ID
        )[0].PagoMensual;

        if (this.model.FechaRecibido) {
          this.model.FechaRecibido = new Date(this.model.FechaRecibido);
          this.model.SelectedDate = this.getDateStructFromDate(this.model.FechaRecibido);
        }
        if (this.model.FechaBancaria) {
          this.model.FechaBancaria = new Date(this.model.FechaBancaria);
          this.model.SelectedBancoDate = this.getDateStructFromDate(this.model.FechaBancaria);
        }
        
        this.modalRef = this.modalService.show(template, this.config);
      },
      error => this.toastr.error(error.message, 'Error!', {disableTimeOut: true, closeButton: true}) );
    }
  }

  async onCancelar(id: number) {
    const { value: formValues } = await Swal.fire({
      title: 'Cancelar',
      html:
        'Observacion: <br> <input id="swal-input1" class="swal2-input">' ,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      preConfirm: () => {
        return [
          (<any>document.getElementById('swal-input1')).value
        ];
      }
    });

    if (formValues) {
      let obs = formValues[0];

      const modelAut = {
        id: id,
        motivo: obs,
      };

      this._pagoService.cancelar(modelAut)
      .subscribe(
        success => {
          Swal.fire({
            title: 'Cancelado!',
            text: 'Pago cancelado con exito.',
            type: 'success',
            confirmButtonText: 'Aceptar'
          });
          this.onBuscar();
        },
        error => {
          this.toastr.error(error.message, 'Error!', {disableTimeOut: true, closeButton: true});
        });
    }
  }

  onSubmit(FormData) {
    if (FormData.valid) {
      this._pagoService.guardar(this.model)
    .subscribe(
      success => {
        this.toastr.success('Pago guardado con exito.', 'Guardado!');
        this.onBuscar();
        FormData.resetForm();
        this.modalRef.hide();
      },
      error => {
        this.toastr.error(error.message, 'Error!', {disableTimeOut: true, closeButton: true});
      });
    }
  }

  onFechaChanged(selectedDate: NgbDateStruct) {
    this.model.FechaRecibido = this.getDateFromDateStruct(selectedDate);
  }

  onFechaBancoChanged(selectedDate: NgbDateStruct) {
    this.model.FechaBancaria = this.getDateFromDateStruct(selectedDate);
  }

  getDateFromDateStruct(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  getDateStructFromDate(date: Date): NgbDateStruct {
    const dateStruct: NgbDateStruct = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    };

    return dateStruct;
  }

  onClienteChanged(selectedValue: any) {
    if (!selectedValue) {
      this.creditosList = [];
      return;
    }
    this.creditosList = [];

    this.creditosList = this.creditos.filter(
      item => item.Cliente_ID === selectedValue.ID
    );
  }

  onCreditoChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.pagoEsperado = selectedValue.PagoMensual;

    this._pagoService.pagoRequerido(this.model)
      .subscribe(
        data => {
          this.pagoEsperado = data.PagoRequerido;
          this.pagoTotalRequerido = data.PagoRequeridoTotal;
        },
        error => this.toastr.error(error.message, 'Error!', {disableTimeOut: true, closeButton: true}) );
  }

  onImportePagoChanged() {
     if (this.model.ImportePago > this.pagoEsperado) {
        this.excedePago =  true;
        Swal.fire({
          title: 'Importe de Pago!',
          text: 'El importe supera el pago esperado. El excedente del pago se abonará a Capital.',
          type: 'info',
          confirmButtonText: 'Aceptar'
        });
     } else if (this.model.ImportePago < this.pagoEsperado) {
      Swal.fire({
        title: 'Importe de Pago!',
        text: 'El importe no es suficiente para cubrir la mensualidad',
        type: 'error',
        confirmButtonText: 'Aceptar'});
     }
  }

  onImprimir(id: number) {
    const model = {
      id: id
    };
    this._reporteService.imprimirRecibo(model).subscribe(
      (data: any ) => {
        const fileblob = new Blob([data], {type: 'application/pdf'});
        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
            window.navigator.msSaveOrOpenBlob(fileblob, 'ReportePagos.pdf');
        } else {
            const fileURL = URL.createObjectURL(fileblob);
            window.open(fileURL);
        }
      },
      (error) => {
        this.toastr.error(error.message, 'Error!');
      }
    );
  }
}
