import { Component, OnInit } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { Router } from '@angular/router';
import { ReportesService, UsersService } from 'src/app/services/service.index';

@Component({
  selector: 'app-reporte-1-creditos',
  templateUrl: './reporte-1-creditos.component.html',
  styleUrls: ['./reporte-1-creditos.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class Reporte1CreditosComponent implements OnInit {
  fraccionamientosS: any[] = [];
  clientes: any[] = [];
  estatusList = [{ID: 1,  Nombre: 'Todos', Valor: '' }, {ID: 2,  Nombre: 'Vigente', Valor: 'V' }, {ID: 2,  Nombre: 'Pagado', Valor: 'P' }, {ID: 3,  Nombre: 'Cancelado', Valor: 'C' }];

  fraccionamiento: number = -1;
  estatus = '';

  constructor(private _userService: UsersService, private _reporteService: ReportesService, private toastr: ToastrService, public router: Router) {
    this._userService.loadStorage();
    this._userService.loadFraccionamientoStorage();
  }

  ngOnInit() {
    this.getCombos();
  }

  onGenerar(FormData) {
    if (FormData.valid) {
      const model = {
        fraccionamiento: this.fraccionamiento,
        estatus: this.estatus
      };
      this._reporteService.imprimirCreditos(model).subscribe(
        (data: any ) => {
          const fileblob = new Blob([data], {type: 'application/pdf'});
          if (navigator.appVersion.toString().indexOf('.NET') > 0) {
              window.navigator.msSaveOrOpenBlob(fileblob, 'ReportePagos.pdf');
          } else {
              const fileURL = URL.createObjectURL(fileblob);
              window.open(fileURL);
          }
        },
        (error) => {
          this.toastr.error(error.message, 'Error!');
        }
      );
    }
  }

  getCombos() {
    this._reporteService.getCombos()
      .subscribe(
        data => {
          this.fraccionamientosS = data.fraccionamientosF;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  onCerrar() {
    this.router.navigate(['/dashboard']);
  }
}
