import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from 'src/app/services/service.index';
import { NotariasService } from '../../services/notarias/notarias.service';
import { Notaria } from 'src/app/models/Notaria';

@Component({
  selector: 'app-notarias',
  templateUrl: './notarias.component.html',
  styleUrls: ['./notarias.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class NotariasComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };

  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-md'
  };

  descripcion: string = '';
  estatus: any;
  lista: any[] = [];
  estatusList = [{ID: 1,  Nombre: 'Todos', Valor: -1 }, {ID: 2,  Nombre: 'Activo', Valor: 1 }, {ID: 2,  Nombre: 'Inactivo', Valor: 0 }];

  model: Notaria = new Notaria();

  constructor(private _userService: UsersService, private _notariaService: NotariasService, private modalService: BsModalService, private toastr: ToastrService) {
    this._userService.loadStorage();
   }

  ngOnInit() {
    this.onBuscar();
  }

  onBuscar() {
    this._notariaService.getLista(this.descripcion, this.estatus).subscribe(
      (data: any) => {
        this.lista = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.model = new Notaria();
    if (id <= 0) {
      this.modalRef = this.modalService.show(template, this.config);
    } else {
      this._notariaService.getNotaria(id)
    .subscribe(
      data => {
        this.model = data;

        this.modalRef = this.modalService.show(template, this.config);
      },
      error => this.toastr.error(error.message, 'Error!') );
    }
  }

  onDelete(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar Notaria, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        this._notariaService.eliminar(id)
        .subscribe(
          success => {
            this.onBuscar();
            Swal.fire({
              title: 'Eliminado!',
              text: 'Notaria a sido eliminado con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
        
      }
    });
  }

  onSubmit(FormData) {
    if (FormData.valid) {
      this._notariaService.guardar(this.model)
    .subscribe(
      success => {
        this.toastr.success('Notaria guardado con exito.', 'Guardado!');
        this.onBuscar();
        FormData.resetForm();
        this.modalRef.hide();
      },
      error => {
        this.toastr.error(error.message, 'Error!');
      });
    }
  }

}
