import { Component, OnInit } from '@angular/core';
import { UsersService, DashboardService } from 'src/app/services/service.index';
import { Fraccionamiento } from '../../models/Fraccionamiento';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  fraccionamientoSelected:any;
  lista: any[] = [];
  radioPrefix = 'control_';
  constructor(private _userService: UsersService, private _dashboardService: DashboardService) { 
    this._userService.loadStorage();
    this._userService.loadFraccionamientoStorage();

    this.fraccionamientoSelected = this._userService.fraccionamiendoID;
  }

  ngOnInit() {
    this.getLoad();
  }

  onRadioChanged() {
    this._userService.saveFraccionamientoStorage(this.fraccionamientoSelected);
  }

  getLoad() {
    this._dashboardService.getDashboardFraccionamiento()
      .subscribe(
        data => {
          this.lista = data.fraccionamientos;
          let tmpFracc = new Fraccionamiento();
          tmpFracc.ID = -1;
          tmpFracc.Nombre = 'Todos';
          tmpFracc.Imagen = 'assets/images/default-upload.png';

          this.lista.unshift(tmpFracc);
        },
        error => { 
          let tmpFracc = new Fraccionamiento();
          tmpFracc.ID = -1;
          tmpFracc.Nombre = 'Todos';
          tmpFracc.Imagen = 'assets/images/default-upload.png';

          this.lista.unshift(tmpFracc);
        });
  }

}
