import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ControlCreditosService, UsersService } from 'src/app/services/service.index';
import { Router } from '@angular/router';
import { ControlCreditoDto } from '../../models/ControlCreditoDto';
import { Mensualidad } from '../../models/Mensualidad';
import { CorridaDetalle } from '../../models/CorridaDetalle';
import { Pago } from '../../models/Pago';
import { PagosService } from '../../services/pagos/pagos.service';
import { Corrida } from '../../models/Corrida';
import { ReportesService } from '../../services/reportes/reportes.service';

@Component({
  selector: 'app-control-credito-form',
  templateUrl: './control-credito-form.component.html',
  styleUrls: ['./control-credito-form.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class ControlCreditoFormComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };

  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-md'
  };

  model: ControlCreditoDto = new ControlCreditoDto();
  mensualidadConDetalles: Mensualidad[] = [];
  CorridaDetalle: CorridaDetalle[] = [];
  mensualidadDetalles: Mensualidad[] = [];
  tmpMensualidadDetalles: Mensualidad[] = [];
  PagosDetalle: any[] = [];
  fechaPago: any;
  fechaPagoSelect: NgbDateStruct;
  fechaRefEstado: any;
  fechaRefEstadoSelect: NgbDateStruct;

  // Pagos
  from: NgbDateStruct;
  to: NgbDateStruct;
  folio: string = '';
  cliente: string = '';
  descripcion: string = '';
  estatus: any;
  fraccionamiento:any;
  manzana:any;
  lote:any;
  moneda: any;
  bancario: any;
  formapago: any;
  lista: any[] = [];

  modelP: Pago = new Pago();
  pagoEsperado:any;
  pagoTotalRequerido:any;
  excedePago = false;
  esEditar = false;

  manzanasF: any[] = [];
  fraccionamientosF: any[] = [];
  lotesF: any[] = [];
  clientes: any[] = [];
  formapagos: any[] = [];
  formapagosF: any[] = [];
  cuentas: any[] = [];
  cuentasF: any[] = [];
  creditos: any[] = [];
  creditosList: any[] = [];

  estatusList = [{ID: 1,  Nombre: 'Todos', Valor: -1 }, {ID: 2,  Nombre: 'Vigente', Valor: 1 }, {ID: 3,  Nombre: 'Cancelado', Valor: 0 }];
  monedaList = [{ID: 1,  Nombre: 'Todos', Valor: -1 }, {ID: 1,  Nombre: 'MX', Valor: 1 }, {ID: 2,  Nombre: 'USD', Valor: 2 }];
  monedaPagosList = [{ID: 1,  Nombre: 'MX', Valor: 1 }, {ID: 2,  Nombre: 'USD', Valor: 2 }];
  formas = [{ID: 1,  Nombre: 'Abono a Capital ', Valor: true }, {ID: 2,  Nombre: 'Pagos Anticipados', Valor: false }];

  constructor(private _userService: UsersService, private _creditoService: ControlCreditosService, private _pagoService: PagosService, private _reporteService: ReportesService, private modalService: BsModalService, private toastr: ToastrService, public router: Router) { 
    this._userService.loadStorage();
    this.fechaRefEstado = new Date();
    this.fechaRefEstado.setHours(0,0,0,0);
    
    this.fechaRefEstadoSelect = {
      day: this.fechaRefEstado.getDate(),
      month: this.fechaRefEstado.getMonth() + 1,
      year: this.fechaRefEstado.getFullYear() };

    this.model = this.router.getCurrentNavigation().extras.state.model as ControlCreditoDto;
    this.mensualidadConDetalles = this.router.getCurrentNavigation().extras.state.mensualidades as Mensualidad[];
    this.PagosDetalle = this.router.getCurrentNavigation().extras.state.pagos as any[];
    this.model.FechaCredito = new Date(this.model.FechaCredito);
    this.onCalcularCorrida();
    this.onCalcularCredito();
  }

  ngOnInit() {
    this.getCombos();
  }

  async onCancelar() {
    const { value: formValues } = await Swal.fire({
      title: 'Cancelar',
      html:
        'Observación: <br> <input id="swal-input1" class="swal2-input">' ,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      preConfirm: () => {
        return [
          (<any>document.getElementById('swal-input1')).value
        ];
      }
    });

    if (formValues) {
      let obs = formValues[0];

      const modelAut = {
        id: this.model.Credito_ID,
        motivo: obs,
      };

      this._creditoService.cancelar(modelAut)
      .subscribe(
        success => {
          Swal.fire({
            title: 'Cancelado!',
            text: 'Credito cancelado con exito.',
            type: 'success',
            confirmButtonText: 'Aceptar'
          });
          this.router.navigate(['/controlcreditos']);
        },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
    }
  }

  onpagoRequerido() {
    if (!this.fechaPagoSelect) {
      return;
    }

    const model = {
      credito_ID: this.model.Credito_ID,
      fechaPago: this.fechaPago
    };
    
    this._creditoService.pagoRequerido(model)
      .subscribe(
        data => {
          this.model.PagoRequerido = data.PagoRequerido;
        },
        error => this.toastr.error(error.message, 'Error!', {disableTimeOut: true, closeButton: true}) );
  }

  onFechaChanged(selectedDate: NgbDateStruct) {
    this.fechaPago = this.getDateFromDateStruct(selectedDate);
  }

  onFechaPagoChanged(selectedDate: NgbDateStruct) {
    this.modelP.FechaRecibido = this.getDateFromDateStruct(selectedDate);
  }

  onFechaBancoChanged(selectedDate: NgbDateStruct) {
    this.modelP.FechaBancaria = this.getDateFromDateStruct(selectedDate);
  }

  onFechaRefEstadoChanged(selectedDate: NgbDateStruct) {
    this.fechaRefEstado = this.getDateFromDateStruct(selectedDate);
  }
  
  getDateFromDateStruct(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  onCerrar() {
    this.router.navigate(['/controlcreditos']);
  }
  
  onCalcularCorrida() {
    this.CorridaDetalle = [];
    let fechaCorrida = new Date(this.model.FechaCredito.getFullYear(), this.model.FechaCredito.getMonth() , this.model.FechaCredito.getDate());
    let fechaCorridaPrimera = new Date(this.model.FechaCredito.getFullYear(), this.model.FechaCredito.getMonth() , this.model.FechaCredito.getDate());
    
    let incrementoMes = 1;

    let ban = true;
    let index = 0;
    for (index; ban; index++) {
      let PagoMensual = 0;

      fechaCorrida = this.addMonths(fechaCorrida, incrementoMes);
      let noMes = Number((fechaCorrida.getMonth() + 1));
      let detalle = new CorridaDetalle();

      // Numero de mes
      detalle.ID = index + 1;

      // Dia Pago
      if (Number((fechaCorrida.getMonth() + 1))  === 2) {
        if (Number(this.model.DiaPago) === 31 || Number(this.model.DiaPago) === 30 || Number(this.model.DiaPago) === 29) {
          if (this.onAnoEsBiciesto(fechaCorrida.getFullYear())) {
            detalle.Mes = 29;
          } else {
            let dia = this.onDiasEnMes(fechaCorrida.getMonth() + 1, fechaCorrida.getFullYear());
            detalle.Mes = dia;
          }
        } else {
          detalle.Mes = this.model.DiaPago;
        }
      } else {
        if (Number(this.model.DiaPago) === 31) {
          let dia31 = this.onDiasEnMes(fechaCorrida.getMonth() + 1, fechaCorrida.getFullYear());
          detalle.Mes = dia31;
        } else {
          detalle.Mes = this.model.DiaPago;
        }
      }

      let fechaCadena = fechaCorrida;
      detalle.Fecha = this.onFormatDateToString(fechaCadena, detalle.Mes);

      // Importe
      // detalle.Importe = this.model.PagoMensual;
      // if (index !== 0) {
      //    if (this.CorridaDetalle[index - 1 ].Saldo <= this.model.PagoMensual) {
      //       PagoMensual = this.CorridaDetalle[index - 1].Saldo;
      //       detalle.Importe = PagoMensual;
      //       ban = false;
      //    }
      // }

      // NUEVO TOMADO DE CREDITO
      detalle.Importe = 0;
      if (noMes === Number(this.model.noMensAnual)) {
        detalle.Importe = this.model.anualidad;
        detalle.RestaImporte = this.model.anualidad;
        detalle.PagoMensualidad = this.model.anualidad;
      } else {
        detalle.Importe = this.model.PagoMensual;
        detalle.RestaImporte = this.model.PagoMensual;
        detalle.PagoMensualidad = this.model.PagoMensual;
      }

      if (index !== 0) {
        if (this.model.conAnualidad) {
          if (this.CorridaDetalle[index - 1 ].Saldo <= detalle.Importe) {
            PagoMensual = this.CorridaDetalle[index - 1].Saldo;
            detalle.Importe = 0;
            detalle.Importe = PagoMensual;
            detalle.RestaImporte = PagoMensual;
            console.log(detalle.RestaImporte);
            ban = false;
          }
        } else {
          if (this.CorridaDetalle[index - 1 ].Saldo <= this.model.PagoMensual) {
            PagoMensual = this.CorridaDetalle[index - 1].Saldo;
            detalle.Importe = 0;
            detalle.Importe = PagoMensual;
            detalle.RestaImporte = PagoMensual;
  
            ban = false;
          }
        }
           
      }

      // Capital
      if (index === 0) {
        detalle.Capital = this.model.TotalCredito;
      } else {
        detalle.Capital = this.CorridaDetalle[index - 1 ].Saldo;
      }

      // Interes
      let diasTranscurridos = 0;
      if (this.model.TipoInteres == 1) {
        if (index === 0) {
          let dateParts = detalle.Fecha.split('/');
          // month is 0-based, that's why we need dataParts[1] - 1
          let dateObject = new Date(+dateParts[2], Number(dateParts[1]) - 1, +dateParts[0]);
  
          let difference_In_Time = dateObject.getTime() - fechaCorridaPrimera.getTime();
          let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
  
           diasTranscurridos = difference_In_Days;
           detalle.Interes = ((detalle.Capital) * (this.model.InteresAnual/100) / 360) * diasTranscurridos;
        } else {
          let dateParts1 = detalle.Fecha.split('/');
          let dateObject1 = new Date(+dateParts1[2], Number(dateParts1[1]) - 1, +dateParts1[0]);
  
          let dateParts2 = this.CorridaDetalle[index - 1 ].Fecha.split('/');
          let dateObject2 = new Date(+dateParts2[2], Number(dateParts2[1]) - 1, +dateParts2[0]);
  
          let difference_In_Time = dateObject1.getTime() - dateObject2.getTime();
          let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
  
          diasTranscurridos = difference_In_Days;
          detalle.Interes = ((this.CorridaDetalle[index - 1 ].Saldo) * (this.model.InteresAnual/100) / 360) * diasTranscurridos;
        }
      } else {
        if (index === 0) {
          detalle.Interes = ((detalle.Capital) * (this.model.InteresAnual/100) / 12);
        } else {
          detalle.Interes = ((this.CorridaDetalle[index - 1 ].Saldo) * (this.model.InteresAnual/100) / 12);
        }
      }

      

      // Seguro
      // if (index >= 1) {
      //   detalle.Seguro = (this.CorridaDetalle[index - 1 ].Saldo * (this.model.PorcSeguro / 100)) / 1000;
      // } else {
      //   if(this.model.CobrarSeguro) {
      //     detalle.Seguro = (detalle.Capital * (this.model.PorcSeguro / 100)) / 1000;
      //   } else {
      //     detalle.Seguro = 0;
      //   }
      // }
      // NUEVO
      if (index >= 1) {
        detalle.Seguro = (this.CorridaDetalle[index - 1 ].Saldo * (this.model.PorcSeguro / 100)) / 1000;
      } else {
        if(this.model.CobrarSeguro) {
          detalle.Seguro = (detalle.Capital * (this.model.PorcSeguro / 100)) / 1000;
        } else {
          detalle.Seguro = 0;
        }
      }

      // if (!ban) {
      //   detalle.Importe = detalle.Importe + detalle.Interes + detalle.Seguro;
      // }

      if (!ban) {
        //detalle.Importe = 0; // detalle.Importe + detalle.Interes + detalle.Seguro;
        detalle.Importe = detalle.Importe + detalle.Interes + detalle.Seguro;
        detalle.RestaImporte = detalle.RestaImporte + detalle.Interes + detalle.Seguro;
        
        detalle.PagoMensualidad = detalle.RestaImporte;
      }

      // Capital
      detalle.PagoCapital = detalle.Importe - detalle.Interes - detalle.Seguro;

      // Saldo
      detalle.Saldo = detalle.Capital - detalle.PagoCapital;

      this.CorridaDetalle.push(detalle);
    }

  //   if (index !== Number(this.model.Plazo)) {
  //     Swal.fire({
  //       title: 'Error Corrida!',
  //       text: 'Pago mensual NO corresponde al importe al plazo estimado, por favor, cambie el pago mensual y vuelva a recalcular. Numero de pazo generado: '+ index,
  //       type: 'error',
  //       confirmButtonText: 'Aceptar'
  //     });
  //  }
  }

  onCalcularCredito() {
    this.tmpMensualidadDetalles = [];
    let fechaCorrida = new Date(this.model.FechaCredito.getFullYear(), this.model.FechaCredito.getMonth() , this.model.FechaCredito.getDate());
    let fechaCorridaPrimera = new Date(this.model.FechaCredito.getFullYear(), this.model.FechaCredito.getMonth() , this.model.FechaCredito.getDate());
  
    let incrementoMes = 1;
    let ban = true;
    let index = 0;
    for (index; ban; index++) {
      let PagoMensual = 0;
  
      fechaCorrida = this.addMonths(fechaCorrida, incrementoMes);
      let noMes = Number((fechaCorrida.getMonth() + 1));
      let detalle = new Mensualidad();
  
      // Numero de mes
      detalle.NoConsecutivo = index + 1;
  
      // Dia Pago
      if (Number((fechaCorrida.getMonth() + 1))  === 2) {
        if (Number(this.model.DiaPago) === 31 || Number(this.model.DiaPago) === 30 || Number(this.model.DiaPago) === 29) {
          if (this.onAnoEsBiciesto(fechaCorrida.getFullYear())) {
            detalle.DiaMes = 29;
          } else {
            let dia = this.onDiasEnMes(fechaCorrida.getMonth() + 1, fechaCorrida.getFullYear());
            detalle.DiaMes = dia;
          }
        } else {
          detalle.DiaMes = this.model.DiaPago;
        }
      } else {
        if (Number(this.model.DiaPago) === 31) {
          let dia31 = this.onDiasEnMes(fechaCorrida.getMonth() + 1, fechaCorrida.getFullYear());
          detalle.DiaMes = dia31;
        } else {
          detalle.DiaMes = this.model.DiaPago;
        }
      }
  
      let fechaCadena = fechaCorrida;
  
      detalle.FechaVencimiento = new Date(fechaCadena.getFullYear(), fechaCadena.getMonth() , detalle.DiaMes);
  
      // detalle.Importe = 0;
      // detalle.PagoRequerido = this.model.PagoMensual;
      // detalle.RestaImporte = this.model.PagoMensual;
      // detalle.PagoMensualidad = this.model.PagoMensual;
      // if (index !== 0) {
      //      if (this.tmpMensualidadDetalles[index - 1 ].NuevoSaldo <= this.model.PagoMensual) {
      //         PagoMensual = this.tmpMensualidadDetalles[index - 1].NuevoSaldo;
      //         detalle.Importe = 0;
      //         detalle.PagoRequerido = PagoMensual;
      //         detalle.RestaImporte = PagoMensual;
      //         ban = false;
      //   }
      // }
      detalle.Importe = 0;
      if (noMes === Number(this.model.noMensAnual)) {
        detalle.PagoRequerido = this.model.anualidad;
        detalle.RestaImporte = this.model.anualidad;
        detalle.PagoMensualidad = this.model.anualidad;
      } else {
        detalle.PagoRequerido = this.model.PagoMensual;
        detalle.RestaImporte = this.model.PagoMensual;
        detalle.PagoMensualidad = this.model.PagoMensual;
      }
  
      if (index !== 0) {
        if (this.model.conAnualidad) {
          if (this.tmpMensualidadDetalles[index - 1 ].NuevoSaldo <= detalle.PagoRequerido) {
            PagoMensual = this.tmpMensualidadDetalles[index - 1].NuevoSaldo;
            detalle.Importe = 0;
            detalle.PagoRequerido = PagoMensual;
            detalle.RestaImporte = PagoMensual;
            console.log(detalle.RestaImporte);
            ban = false;
          }
        } else {
          if (this.tmpMensualidadDetalles[index - 1 ].NuevoSaldo <= this.model.PagoMensual) {
            PagoMensual = this.tmpMensualidadDetalles[index - 1].NuevoSaldo;
            detalle.Importe = 0;
            detalle.PagoRequerido = PagoMensual;
            detalle.RestaImporte = PagoMensual;
  
            ban = false;
          }
        }
           
      }

      // Capital
      if (index === 0) {
        detalle.CapitalInicial = this.model.TotalCredito;
      } else {
        detalle.CapitalInicial = this.tmpMensualidadDetalles[index - 1 ].NuevoSaldo;
      }
  
      let diasTranscurridos = 0;
      if (this.model.TipoInteres == 1) {
        if (index === 0) {
          let difference_In_Time = detalle.FechaVencimiento.getTime() - fechaCorridaPrimera.getTime();
          let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
  
          diasTranscurridos = difference_In_Days;
          detalle.DiasTranscurridos = diasTranscurridos;
          detalle.Interes = ((detalle.CapitalInicial) * (this.model.InteresAnual/100) / 360) * diasTranscurridos;
        } else {
          let difference_In_Time = detalle.FechaVencimiento.getTime() - this.tmpMensualidadDetalles[index - 1 ].FechaVencimiento.getTime();
          let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
  
          diasTranscurridos = difference_In_Days;
          detalle.DiasTranscurridos = diasTranscurridos;
          detalle.Interes = ((this.tmpMensualidadDetalles[index - 1 ].NuevoSaldo) * (this.model.InteresAnual/100) / 360) * diasTranscurridos;
        }
      } else {
        if (index === 0) {
          detalle.Interes = ((detalle.CapitalInicial) * (this.model.InteresAnual/100) / 12);
        } else {
          detalle.Interes = ((this.tmpMensualidadDetalles[index - 1 ].NuevoSaldo) * (this.model.InteresAnual/100) / 12);
        }
      }
      
  
  
      // Seguro
      if (index >= 1) {
        detalle.Seguro = (this.tmpMensualidadDetalles[index - 1 ].NuevoSaldo * (this.model.PorcSeguro / 100)) / 1000;
      } else {
        if(this.model.CobrarSeguro) {
          detalle.Seguro = (detalle.CapitalInicial * (this.model.PorcSeguro / 100)) / 1000;
        } else {
          detalle.Seguro = 0;
        }
      }
  
      if (!ban) {
        detalle.Importe = 0; // detalle.Importe + detalle.Interes + detalle.Seguro;
        detalle.PagoRequerido = detalle.PagoRequerido + detalle.Interes + detalle.Seguro;
        detalle.RestaImporte = detalle.RestaImporte + detalle.Interes + detalle.Seguro;
        detalle.PagoMensualidad = detalle.RestaImporte;
      }
  
      // Capital
      detalle.PagoCapital = detalle.PagoRequerido - detalle.Interes - detalle.Seguro; // detalle.Importe - detalle.Interes - detalle.Seguro;
  
      // Saldo
      detalle.NuevoSaldo = detalle.CapitalInicial - detalle.PagoCapital;
  
      this.tmpMensualidadDetalles.push(detalle);
    }
  
    this.mensualidadDetalles = this.tmpMensualidadDetalles;
  
   }

  addMonths(date: Date, months: Number) {
    let d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() !== d) {
      date.setDate(0);
    }
    return date;
  }

  onAnoEsBiciesto(year: number) {
    return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
  }
  onDiasEnMes(month: number, year: number) {
    return new Date(year, month, 0).getDate();
  }

  onFormatDateToString(date, day) {
    // 01, 02, 03, ... 29, 30, 31
    var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
    // 01, 02, 03, ... 10, 11, 12
    var MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
    // 1970, 1971, ... 2015, 2016, ...
    var yyyy = date.getFullYear();

    var ddf = (day < 10 ? '0' : '') + day;

    // create the format you want
    return (ddf + '/' + MM + '/' + yyyy);
 }

 // Pagos form
 getCombos() {
  this._pagoService.getCombos()
    .subscribe(
      data => {
        this.manzanasF = data.manzanasF;
        this.fraccionamientosF = data.fraccionamientosF;
        this.lotesF = data.lotesF;
        this.clientes = data.clientes;
        this.formapagos = data.formapagos;
        this.formapagosF = data.formapagosF;
        this.cuentas = data.cuentas;
        this.cuentasF = data.cuentasF;
        this.creditos = data.creditos;
      },
      error => this.toastr.error(error.message, 'Error!', {disableTimeOut: true, closeButton: true}) );
  }

  onShow(id: number, editar: boolean, template: TemplateRef<any>) {
    this.modelP = new Pago();
    this.creditosList = [];
    this.pagoEsperado = 0;
    this.pagoTotalRequerido = 0;
    this.esEditar = editar;
    this.excedePago = false;

    this.modelP.Cliente_ID = this.model.Cliente_ID;
    this.modelP.Credito_ID = this.model.Credito_ID;
    this.creditosList = this.creditos.filter(
      item => item.Cliente_ID === this.model.Cliente_ID
    );

    this._pagoService.pagoRequerido(this.modelP)
      .subscribe(
        data => {
          this.pagoEsperado = data.PagoRequerido;
        },
        error => this.toastr.error(error.message, 'Error!', {disableTimeOut: true, closeButton: true}) );

    this.modalRef = this.modalService.show(template, this.config);
  }

  onSubmit(FormData) {
    if (FormData.valid) {
      this._pagoService.guardar(this.modelP)
    .subscribe(
      success => {
        this.toastr.success('Pago guardado con exito.', 'Guardado!');
        FormData.resetForm();
        this.modalRef.hide();
      },
      error => {
        this.toastr.error(error.message, 'Error!', {disableTimeOut: true, closeButton: true});
      });
    }
  }

  onFechaPChanged(selectedDate: NgbDateStruct) {
    this.modelP.FechaRecibido = this.getDateFromDateStruct(selectedDate);
  }

  getDateStructFromDate(date: Date): NgbDateStruct {
    const dateStruct: NgbDateStruct = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    };

    return dateStruct;
  }

  onClienteChanged(selectedValue: any) {
    if (!selectedValue) {
      this.creditosList = [];
      return;
    }
    this.creditosList = [];

    this.creditosList = this.creditos.filter(
      item => item.Cliente_ID === selectedValue.ID
    );
  }

  onCreditoChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.pagoEsperado = selectedValue.PagoMensual;

    this._pagoService.pagoRequerido(this.modelP)
      .subscribe(
        data => {
          this.pagoEsperado = data.PagoRequerido;
          this.pagoTotalRequerido = data.PagoRequeridoTotal;
        },
        error => this.toastr.error(error.message, 'Error!', {disableTimeOut: true, closeButton: true}) );
  }

  onImportePagoChanged() {
     if (this.modelP.ImportePago > this.pagoEsperado) {
        this.excedePago =  true;
        Swal.fire({
          title: 'Importe de Pago!',
          text: 'El importe supera el pago esperado. El excedente del pago se abonará a Capital.',
          type: 'info',
          confirmButtonText: 'Aceptar'
        });
     } else if (this.modelP.ImportePago < this.pagoEsperado) {
      Swal.fire({
        title: 'Importe de Pago!',
        text: 'El importe no es suficiente para cubrir la mensualidad',
        type: 'error',
        confirmButtonText: 'Aceptar'});
     }
  }

  onReprocesarCredito() {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere reprocesar credito, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Reprocesar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {

        const model = {
          credito_ID: this.model.Credito_ID,
          mensualidades: this.mensualidadDetalles
        };

        this._creditoService.reprocesarCredito(model)
        .subscribe(
          success => {
            Swal.fire({
              title: 'Procesado!',
              text: 'Credito ha sido reprocesado con exito',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
            this.router.navigate(['/controlcreditos']);
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
      }
    });
  }
  
  onRevisarFechaRef(fecha) {
    if(fecha >= this.fechaRefEstado){
      return 'SIN VENCER';
    }
    else if(fecha < this.fechaRefEstado)
    {
      return 'VENCIDA';
    } 
    else 
    {
      return 'NA';
    }
  }

  onImprimirCorrida() {
    let modelCorrida = new Corrida();
    modelCorrida.ID = 0;
    modelCorrida.Fecha = this.model.FechaCredito;
    modelCorrida.Plazo = this.model.Plazo;
    modelCorrida.Interes = this.model.InteresAnual;
    modelCorrida.Precio = this.model.PrecioLote;
    modelCorrida.Enganche = this.model.Enganche;
    modelCorrida.TotalCredito = this.model.TotalCredito;
    modelCorrida.PagoMensual = this.model.PagoMensual;
    modelCorrida.DiaPago = this.model.DiaPago;
    modelCorrida.Moneda = 0;
    modelCorrida.Observaciones = '';
    modelCorrida.Cliente_ID = this.model.Cliente_ID;
    modelCorrida.Lote_ID = this.model.Lote_ID;
    modelCorrida.Estatus = '';
    modelCorrida.Manzana_ID = this.model.Manzana_ID;
    modelCorrida.Fraccionamieto_ID = this.model.Fraccionamieto_ID;

    const model = {
      corrida: modelCorrida,
      detallecorrida: this.CorridaDetalle
    };

    this._reporteService.imprimirCorrida(model).subscribe(
      (data: any ) => {
        const fileblob = new Blob([data], {type: 'application/pdf'});
        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
            window.navigator.msSaveOrOpenBlob(fileblob, 'ReportePagos.pdf');
        } else {
            const fileURL = URL.createObjectURL(fileblob);
            window.open(fileURL);
        }
      },
      (error) => {
        this.toastr.error(error.message, 'Error!');
      }
    );
  }

  onImprimirCredito() {
    const model = {
      id: this.model.Credito_ID
    };
    this._reporteService.imprimirCredito(model).subscribe(
      (data: any ) => {
        const fileblob = new Blob([data], {type: 'application/pdf'});
        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
            window.navigator.msSaveOrOpenBlob(fileblob, 'ReportePagos.pdf');
        } else {
            const fileURL = URL.createObjectURL(fileblob);
            window.open(fileURL);
        }
      },
      (error) => {
        this.toastr.error(error.message, 'Error!');
      }
    );
  }

  onImprimirPagos() {
      let from = '';
      let to = '';
      if (this.from !== undefined && this.to !== undefined) {
        from = `${this.from.year}-${this.from.month}-${this.from.day}`;
        to = `${this.to.year}-${this.to.month}-${this.to.day}`;
      }

      const model = {
        from: from,
        to: to,
        fraccionamiento: this.model.Fraccionamieto_ID,
        cliente: this.model.Cliente_ID,
        estatus: -1,
        agrupado: 'S',
        ordenado: 'F'
      };
      this._reporteService.imprimirPagos(model).subscribe(
        (data: any ) => {
          const fileblob = new Blob([data], {type: 'application/pdf'});
          if (navigator.appVersion.toString().indexOf('.NET') > 0) {
              window.navigator.msSaveOrOpenBlob(fileblob, 'ReportePagos.pdf');
          } else {
              const fileURL = URL.createObjectURL(fileblob);
              window.open(fileURL);
          }
        },
        (error) => {
          this.toastr.error(error.message, 'Error!');
        }
      );
  }
}
