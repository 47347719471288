import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule, IConfig } from 'ngx-mask';

// Modules
import { SharedModule } from '../shared/shared.module';
import { PagesRoutingModule } from './pages-routing.module';

// Directives
import { DatepickerToggleDirective } from '../directives/datepicker-toggle.directive';

// Routes
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { TiposUsuarioComponent } from './tipos-usuario/tipos-usuario.component';
import { PagosComponent } from './pagos/pagos.component';
import { ControlCreditosComponent } from './control-creditos/control-creditos.component';
import { CreditosComponent } from './creditos/creditos.component';
import { CorridasComponent } from './corridas/corridas.component';
import { ClientesComponent } from './clientes/clientes.component';
import { LocalidadesComponent } from './localidades/localidades.component';
import { FraccionamientosComponent } from './fraccionamientos/fraccionamientos.component';
import { ManzanasComponent } from './manzanas/manzanas.component';
import { FormasPagoComponent } from './formas-pago/formas-pago.component';
import { CuentasComponent } from './cuentas/cuentas.component';
import { NotariasComponent } from './notarias/notarias.component';
import { LotesComponent } from './lotes/lotes.component';
import { ControlCreditoFormComponent } from './control-credito-form/control-credito-form.component';
import { ReportePagosComponent } from './reporte-pagos/reporte-pagos.component';
import { Reporte1CreditosComponent } from './reporte-1-creditos/reporte-1-creditos.component';
import { Reporte2CobranzaComponent } from './reporte-2-cobranza/reporte-2-cobranza.component';
import { Reporte4AtrasadoComponent } from './reporte-4-atrasado/reporte-4-atrasado.component';


@NgModule({
    declarations: [
        DashboardComponent,
        UsuariosComponent,
        TiposUsuarioComponent,
        DatepickerToggleDirective,
        PagosComponent,
        ControlCreditosComponent,
        CreditosComponent,
        CorridasComponent,
        ClientesComponent,
        LocalidadesComponent,
        FraccionamientosComponent,
        ManzanasComponent,
        FormasPagoComponent,
        CuentasComponent,
        NotariasComponent,
        LotesComponent,
        ControlCreditoFormComponent,
        ReportePagosComponent,
        Reporte1CreditosComponent,
        Reporte2CobranzaComponent,
        Reporte4AtrasadoComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        PagesRoutingModule,
        NgSelectModule,
        NgOptionHighlightModule,
        NgbDatepickerModule,
        NgxMaskModule.forRoot()
    ],
    exports: [
        DashboardComponent
    ]
})
export class PagesModule {}
