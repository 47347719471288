import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class Credito {
  ID: number;
  Descripcion: string;
  FechaVenta: any;
  Plazo?: number;
  Interes: number;
  Precio: number;
  Enganche: number;
  TotalCredito: number;
  CapitalActual: number;
  PagoMensual: number;
  DiaPago: number;
  Observaciones: string;
  NoRef: number;
  Moneda: number;
  NotarEstatus?: number;
  NotarImagen: string;
  NotarioObs: string;
  Estatus: string;
  Cliente_ID: number;
  Lote_ID: number;
  Notaria_ID: number;
  Manzana_ID: number;
  Fraccionamieto_ID: number;
  CobrarGastos?: boolean;
  GastoAdmin: number;
  FechaContrato: any;
  CobrarSeguro?: boolean;
  conAnualidad: boolean;
  noMensAnual?: number;
  anualidad?: number;
  TipoInteres: number;
  SelectedDate: NgbDateStruct;
  SelectedContratoDate: NgbDateStruct;

  constructor() {
    this.ID = 0;
    this.Moneda = 1;
    this.FechaVenta = new Date();
    this.FechaContrato = new Date();
    this.Estatus = 'V';
    this.CobrarGastos = false;
    this.CobrarSeguro = false;
    this.GastoAdmin = 0;
    this.SelectedDate = {
        day: this.FechaVenta.getDate(),
        month: this.FechaVenta.getMonth() + 1,
        year: this.FechaVenta.getFullYear() };
    this.SelectedContratoDate = {
        day: this.FechaContrato.getDate(),
        month: this.FechaContrato.getMonth() + 1,
        year: this.FechaContrato.getFullYear() };
  }
}
