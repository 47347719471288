import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class Pago {
  ID: number;
  ImportePago: number;
  FechaRecibido: any;
  FechaBancaria?: any;
  Facturado?: boolean;
  aCapital?: boolean;
  Moneda: number;
  TotalCapital: number;
  TotalIntereses: number;
  TotalSeguros: number;
  TotalMoratorios: number;
  TotalGastos: number;
  Estatus?: boolean;
  Observaciones: string;
  Credito_ID: number;
  FormaPago_ID: number;
  Cuenta_ID: number;
  Cliente_ID: number;
  ReferenciaFact: string;
  CobrarGastos?: boolean;
  SelectedDate: NgbDateStruct;
  SelectedBancoDate: NgbDateStruct;

  constructor() {
    this.ID = 0;
    this.Moneda = 1;
    this.ImportePago = 0;
    this.FechaRecibido = new Date();
    this.Estatus = true;
    this.SelectedDate = {
        day: this.FechaRecibido.getDate(),
        month: this.FechaRecibido.getMonth() + 1,
        year: this.FechaRecibido.getFullYear() };
  }
}
