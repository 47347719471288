export class Lote {
  ID: number;
  Clave: string;
  Mts2?: number;
  Precio?: number;
  Observaciones: string;
  Estatus: string;
  Manzana_ID: number;
  TipoLote_ID: number;
  Fraccionamieto_ID: number;

  constructor() {
    this.ID = 0;
    this.Estatus = 'D';
  }
}
