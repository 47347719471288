import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from 'src/app/services/service.index';
import { Lote } from '../../models/Lote';
import { LotesService } from '../../services/lotes/lotes.service';

@Component({
  selector: 'app-lotes',
  templateUrl: './lotes.component.html',
  styleUrls: ['./lotes.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class LotesComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };

  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-md'
  };

  descripcion: string = '';
  manzana:any;
  estatus = '';
  fraccionaiento:any;
  lista: any[] = [];

  model: Lote = new Lote();
  noLotes = 0;
  noLotesActual = 0;
  esEditar = false;
  esManual = false;

  manzanasF: any[] = [];
  manzanas: any[] = [];
  fraccionamientoF: any[] = [];
  fraccionamiento: any[] = [];
  estatusList = [{ID: 1,  Nombre: 'Todos', Valor: '' }, {ID: 2,  Nombre: 'Disponible', Valor: 'D' }, {ID: 2,  Nombre: 'Vendido', Valor: 'V' }];
  tiposLoteList = [{ID: 1,  Nombre: 'Comercial', Valor: 1 }, {ID: 2,  Nombre: 'Habitacional', Valor: 2 }];

  constructor(private _userService: UsersService, private _loteService: LotesService, private modalService: BsModalService, private toastr: ToastrService) { 
    this._userService.loadStorage();
    this._userService.loadFraccionamientoStorage();

    this.fraccionaiento = this._userService.fraccionamiendoID;
  }

  ngOnInit() {
    this.getCombos();
    this.onBuscar();
  }

  onBuscar() {
    this._loteService.getLista(this.descripcion, this.manzana, this.fraccionaiento, this.estatus).subscribe(
      (data: any) => {
        this.lista = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.model = new Lote();
    this.noLotes = 0;
    this.noLotesActual = 0;
    this.manzanas = [];
    this.esManual = false;
    if (id <= 0) {
      this.esEditar = false;
      this.modalRef = this.modalService.show(template, this.config);
    } else {
      this.esEditar = true;
      this._loteService.getLote(id)
    .subscribe(
      data => {
        this.model = data.model;
        this.noLotesActual = data.noLotesActual;

        this._loteService.getManzanasFraccionamiento(this.model.Fraccionamieto_ID).subscribe(
          (data: any) => {
            this.manzanas = data;
          },
          (error) => {
            this.toastr.error('No existen manzanas en el fraccionamiento', 'Error!');
          }
        );

        this.modalRef = this.modalService.show(template, this.config);
      },
      error => this.toastr.error(error.message, 'Error!') );
    }
  }

  onDelete(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar manzana, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        this._loteService.eliminar(id)
        .subscribe(
          success => {
            this.onBuscar();
            Swal.fire({
              title: 'Eliminado!',
              text: 'Manzana a sido eliminado con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
      }
    });
  }

  onSubmit(FormData) {
    if (FormData.valid) {
      const model = {
        model: this.model,
        noLotes: this.noLotes,
        esManual: this.esManual
      };

      this._loteService.guardar(model)
    .subscribe(
      success => {
        this.toastr.success('Lotes guardados con exito.', 'Guardado!');

        this.onBuscar();
        FormData.resetForm();
        this.modalRef.hide();
      },
      error => {
        this.toastr.error(error.message, 'Error!');
      });

    }
  }

  onFraccionamientoChanged(selectedValue: any) {
    if (!selectedValue) {
      this.manzanas = [];
      return;
    }
    this.manzanas = [];

    this._loteService.getManzanasFraccionamiento(selectedValue.ID).subscribe(
      (data: any) => {
        this.manzanas = data;
      },
      (error) => {
        this.toastr.error('No existen manzanas en el fraccionamiento', 'Error!');
      }
    );
  }

  onManzanaChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.noLotesActual = selectedValue.NoLotes;
  }

  getCombos() {
    this._loteService.getCombos()
      .subscribe(
        data => {
          this.manzanasF = data.manzanaF;
          this.fraccionamientoF = data.fraccionamientoF;
          this.fraccionamiento = data.fraccionamiento;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }
}
