import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from 'src/app/services/service.index';
import { Fraccionamiento } from '../../models/Fraccionamiento';
import { FraccionamientosService } from '../../services/fraccionamientos/fraccionamientos.service';

@Component({
  selector: 'app-fraccionamientos',
  templateUrl: './fraccionamientos.component.html',
  styleUrls: ['./fraccionamientos.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class FraccionamientosComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };

  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-md'
  };

  descripcion: string = '';
  localidad:any;
  estatus:any;
  empresa = -1;
  lista: any[] = [];

  model: Fraccionamiento = new Fraccionamiento();

  localidades: any[] = [];
  localidadesF: any[] = [];
  estatusList = [{ID: 1,  Nombre: 'Todos', Valor: -1 }, {ID: 2,  Nombre: 'Activo', Valor: 1 }, {ID: 2,  Nombre: 'Inactivo', Valor: 0 }];

  public imagePath;
  imgURL: any;
  public message: string;

  constructor(private _userService: UsersService, private _fraccionamientoService: FraccionamientosService, private modalService: BsModalService, private toastr: ToastrService) {
    this._userService.loadStorage();
   }

  ngOnInit() {
    this.imgURL = 'assets/images/default-upload.png';
    this.getCombos();
    this.onBuscar();
  }

  onBuscar() {
    this._fraccionamientoService.getLista(this.descripcion, this.localidad, this.estatus, this.empresa).subscribe(
      (data: any) => {
        this.lista = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.model = new Fraccionamiento();
    this.imgURL = 'assets/images/default-upload.png';
    if (id <= 0) {
      this.modalRef = this.modalService.show(template, this.config);
    } else {
      this._fraccionamientoService.getFraccionamiento(id)
    .subscribe(
      data => {
        this.model = data;
        if (this.model.Imagen !== 'assets/images/default-upload.png') {
          this.imgURL = this.model.Imagen;
        }
        this.modalRef = this.modalService.show(template, this.config);
      },
      error => this.toastr.error(error.message, 'Error!') );
    }
  }

  onDelete(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar fraccionamiento, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        this._fraccionamientoService.eliminar(id)
        .subscribe(
          success => {
            this.onBuscar();
            Swal.fire({
              title: 'Eliminado!',
              text: 'Fraccionamiento a sido eliminado con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
      }
    });
  }

  onSubmit(FormData) {
    if (FormData.valid) {
      if (this.imgURL !== 'assets/images/default-upload.png' && this.imgURL !== this.model.Imagen) {
        this.model.Imagen = this.imgURL;
      }
      this._fraccionamientoService.guardar(this.model)
    .subscribe(
      success => {
        this.toastr.success('Fraccionamiento guardado con exito.', 'Guardado!');
        this.onBuscar();
        FormData.resetForm();
        this.modalRef.hide();
      },
      error => {
        this.toastr.error(error.message, 'Error!');
      });
    }
  }

  getCombos() {
    this._fraccionamientoService.getCombos()
      .subscribe(
        data => {
          this.localidadesF = data.localidadF;
          this.localidades = data.localidad;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    // tslint:disable-next-line: variable-name
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }
}
