export class CorridaDetalle {
  ID: number;
  Mes: number;
  Fecha: string;
  Importe: number;
  RestaImporte: number;
  Capital: number;
  Interes: number;
  Seguro: number;
  PagoCapital: number;
  Saldo: number;
  PagoMensualidad: number;

  constructor() {
  }
}
