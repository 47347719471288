export class FormaPago {
  ID: number;
  Descripcion: string;
  Estatus?: boolean;

  constructor() {
    this.ID = 0;
    this.Estatus = true;
  }
}
