import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { AppSettings } from '../../models/app-settings';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class PagosService {

  private _url = `${AppSettings.API_ENDPOINT}/Pagos`;
  private _getLista = `${this._url}/Lista`;
  private _getGetPago = `${this._url}/GetPago`;
  private _guardar = `${this._url}/Guardar`;
  private _cancelar = `${this._url}/Cancelar`;
  private _getCombos = `${this._url}/Combos`;
  private _pagoRequerido = `${this._url}/PagoRequerido`;

  constructor(public _http: HttpClient, private _userService: UsersService) {
    this._userService.loadStorage();
  }

  getLista(from: String, to: String, folio: String, cliente: String, descripcion: String, estatus: number, fraccionamiento: number, manzana: number, lote: number, moneda: number, bancario: number, formapago: number): Observable<any[]> {
    const params = new HttpParams()
      .set('from', from.toString())
      .set('to', to.toString())
      .set('folio', folio.toString())
      .set('cliente', cliente.toString())
      .set('descripcion', descripcion.toString())
      .set('estatus', String(estatus))
      .set('fraccionamiento', String(fraccionamiento))
      .set('manzana', String(manzana))
      .set('lote', String(lote))
      .set('moneda', String(moneda))
      .set('bancario', String(bancario))
      .set('formapago', String(formapago));

    return this._http.get<any[]>(this._getLista, { params: params, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getPago(id: number): Observable<any>  {
    return this._http.get<any>(`${this._getGetPago}/${id}`, { headers: this._userService.header})
      .pipe(
        tap(data => data),
        catchError(this.handleError)
      );
  }

  guardar(model: any): Observable<any> {
    return this._http.post<any>(`${this._guardar}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  cancelar(model: any) {
    return this._http.post<any>(`${this._cancelar}`, model, { headers: this._userService.header}).pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  pagoRequerido(model: any): Observable<any> {
    return this._http.post<any>(`${this._pagoRequerido}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getCombos(): Observable<any> {
    return this._http.get<any>(this._getCombos, { params: null, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  // Handdle Error methor for observale
  private handleError(err: HttpErrorResponse) {
    return throwError(err.error);
  }
}
