import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { AppSettings } from '../../models/app-settings';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class ReportesService {

  private _url = `${AppSettings.API_ENDPOINT}/Reportes`;
  private _getImprimirPago = `${this._url}/ImprimirReportePago`;
  private _getImprimirCorrida = `${this._url}/ImprimirCorrida`;
  private _getImprimirRecibo= `${this._url}/ImprimirReciboPago`;
  private _getImprimirCredito= `${this._url}/ImprimirCredito`;
  private _getImprimirCreditos = `${this._url}/ImprimirReporteCreditos`;
  private _getImprimirReporteCobranza = `${this._url}/ImprimirReporteCobranza`;
  private _getImprimirReporteAtrasado = `${this._url}/ImprimirReporteAtrasado`;
  private _getCombos = `${this._url}/Combos`;

  constructor(public _http: HttpClient, private _userService: UsersService) {
    this._userService.loadStorage();
  }

  imprimirPagos(model) {
    return  this._http.post(`${this._getImprimirPago}`, model, {responseType: 'arraybuffer', headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  imprimirCorrida(model) {
    return  this._http.post(`${this._getImprimirCorrida}`, model, {responseType: 'arraybuffer', headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  imprimirRecibo(model) {
    return  this._http.post(`${this._getImprimirRecibo}`, model, {responseType: 'arraybuffer', headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  imprimirCredito(model) {
    return  this._http.post(`${this._getImprimirCredito}`, model, {responseType: 'arraybuffer', headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  imprimirCreditos(model) {
    return  this._http.post(`${this._getImprimirCreditos}`, model, {responseType: 'arraybuffer', headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  imprimirCobranza(model) {
    return  this._http.post(`${this._getImprimirReporteCobranza}`, model, {responseType: 'arraybuffer', headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  imprimirAtrasado(model) {
    return  this._http.post(`${this._getImprimirReporteAtrasado}`, model, {responseType: 'arraybuffer', headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getCombos(): Observable<any> {
    return this._http.get<any>(this._getCombos, { params: null, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  // Handdle Error methor for observale
  private handleError(err: HttpErrorResponse) {
    return throwError(err.error);
  }
}
