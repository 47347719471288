import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class Corrida {
  ID: number;
  Fecha: any;
  Plazo?: number;
  Interes: number;
  Precio: number;
  Enganche: number;
  TotalCredito: number;
  PagoMensual: number;
  DiaPago: number;
  Moneda: number;
  Observaciones: string;
  Cliente_ID: number;
  Lote_ID: number;
  Estatus: string;
  Manzana_ID: number;
  Fraccionamieto_ID: number;
  TipoInteres: number;
  conAnualidad: boolean;
  noMensAnual?: number;
  anualidad?: number;
  CobrarGastos?: boolean;
  CobrarSeguro?: boolean;
  SelectedDate: NgbDateStruct;

  constructor() {
    this.ID = 0;
    this.Moneda = 1;
    this.Fecha = new Date();
    this.Estatus = 'V';
    this.CobrarGastos = false;
    this.CobrarSeguro = false;
    this.SelectedDate = {
        day: this.Fecha.getDate(),
        month: this.Fecha.getMonth() + 1,
        year: this.Fecha.getFullYear() };
  }
}
