export class Localidad {
  ID: number;
  Nombre: string;
  Estatus?: boolean;
  
  constructor() {
    this.ID = 0;
    this.Estatus = true;
  }
}
