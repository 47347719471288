import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class Cliente {
  ID: number;
  Nombre: string;
  Direccion: string;
  NumeroInt: string;
  NumeroExt: string;
  Colonia: string;
  Ciudad: string;
  Estado: string;
  CodigoPostal: string;
  Referencia: string;
  Telefono: string;
  Celular: string;
  Email: string;
  Rfc: string;
  Observaciones: string;
  Clasificacion: string;
  FechaCreacion: any;
  EstatusCredito?: boolean;
  esUsuario?: boolean;
  ID_Usuario?: number;
  Empresa_ID?: number;
  Estatus?: boolean;

  constructor() {
    this.ID = 0;
    this.EstatusCredito = false;
    this.Estatus = true;
    this.FechaCreacion = new Date();
  }
}
